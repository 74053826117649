export const treatments = [
    {"part":"肌", "lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"目", "lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"まつ毛", "lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"鼻","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"口元","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"輪郭","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"背中","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"お腹","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"胸","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"審美歯科","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"髪の毛","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"首","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"肩","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"ワキ","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"耳","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"手","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"腕","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"脚","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"ネイル","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"デリケート","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"尻","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"全身","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"その他","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]},
    {"part":"ダイエット","lists":[
        "肌","クマ","脂肪注入（肌）","レーザートーニング（美白・肝斑）","PRP皮膚再生療法","ベビーコラーゲン","スネコス","シワ","肌ヒアルロン酸注入（たるみ・シワ改善）","シワ取りボトックス",
        "ボトックス（額）","再生医療","マイクロボトックス","肌荒れ・ニキビ","ピーリング（ニキビ・毛穴）","ニキビ治療","光治療（フォトフェイシャルなど）","イオン導入","エレクトロポレーション",
        "ルメッカ","ウーバーピール","アグネス","毛穴","ダーマペン","毛穴洗浄（ハイドラフェイシャルなど）","フラクショナルレーザー（毛穴・ニキビ跡）","シミ・そばかす・肝斑（かんぱん）","シミ取りレーザー",
        "ピコレーザートーニング（美白・肝斑）","フォトRF","ピコスポット","リフトアップ","RF（高周波）たるみ治療","肌のハリ・ツヤ","ヴェルヴェットスキン"
    ]}
]