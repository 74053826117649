import part0 from '@/assets/images/part0.png';
import part1 from '@/assets/images/part1.png';
import part2 from '@/assets/images/part2.png';
import part3 from '@/assets/images/part3.png';
import part4 from '@/assets/images/part4.png';
import part5 from '@/assets/images/part5.png';
import part6 from '@/assets/images/part6.png';
import part7 from '@/assets/images/part7.png';
import part8 from '@/assets/images/part8.png';
import part9 from '@/assets/images/part9.png';
import part10 from '@/assets/images/part10.png';
import part11 from '@/assets/images/part11.png';
import part12 from '@/assets/images/part12.png';
import part13 from '@/assets/images/part13.png';
import part14 from '@/assets/images/part14.png';
import part15 from '@/assets/images/part15.png';
import part16 from '@/assets/images/part16.png';
import part17 from '@/assets/images/part17.png';
import part18 from '@/assets/images/part18.png';
import part19 from '@/assets/images/part19.png';
import part20 from '@/assets/images/part20.png';
import part21 from '@/assets/images/part21.png';
import part22 from '@/assets/images/part22.png';

export const partIcon = {
  0: part0, 1: part1, 2: part2, 3: part3, 4: part4, 5: part5, 6: part6, 7: part7, 8: part8, 9: part9, 10: part10, 11: part11,
  12: part12, 13: part13, 14: part14, 15: part15, 16: part16, 17: part17, 18: part18, 19: part19, 20: part20, 21: part21, 22: part22
}
