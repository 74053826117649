<script setup lang="ts">
import { ref } from 'vue';

interface ButtonLocations {
  name: string
  id: string
  left: string
  bottom: string
}

const buttonLocations:Array<ButtonLocations> = [
  {name: "九州・沖縄", id: "A1", left: "15%", bottom: "0%"},
  {name: "中国・四国", id: "A2", left: "20%", bottom: "30%"},
  {name: "関西", id: "A3", left: "50%", bottom: "20%"},
  {name: "関東", id: "A4", left: "75%", bottom: "25%"},
  {name: "中部", id: "A5", left: "45%", bottom: "40%"},
  {name: "東北・北海道", id: "A6", left: "65%", bottom: "65%"},
]

const emits = defineEmits<{
    (e: 'sendArea', data: string): void
}>();
const setColor = (area: string) => {
  if (areaKey.value == area){
    return "#CCA8D2"
  } else {
    return "#C6C3C4"
  }
}
const areaKey = ref("A4")
</script>

<template>
  <v-container width="100%" class="position-relative">
    <img width="100%" height="auto" src="@/assets/images/japan_map.jpg">
    <div v-for="button in buttonLocations" :key="button.id">
      <v-btn @click="emits('sendArea', button.id); areaKey = button.id" variant="outlined" class="font-weight-bold rounded-lg" :style="{ color: setColor(button.id), left: button.left, bottom: button.bottom }"
      style="position: absolute; transform: translate(-50%, -50%); background-color: white;">{{ button.name }}</v-btn>
    </div>
  </v-container>
</template>