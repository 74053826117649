import { setActivePinia, createPinia } from 'pinia';
import { useDataStore } from '@/store/data';
setActivePinia(createPinia());

export const checkData = () => {
  const dataStore = useDataStore();
  if (dataStore.treatments.length === 0) {
    dataStore.fetchTreatments();
  }
  if (dataStore.problems.length === 0) {
    dataStore.fetchProblems();
  }
  if (dataStore.parts.length === 0) {
    dataStore.fetchParts();
  }
  if (dataStore.clinicGroups.length === 0) {
    dataStore.fetchClinicGroups();
  }
  if (dataStore.prefectures.length === 0) {
    dataStore.fetchArea();
  }
  if (dataStore.areas.length === 0) {
    dataStore.fetchArea();
  }
}