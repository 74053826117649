<script setup lang="ts">
import { computed,ref } from 'vue'
import { treatments } from '@/data/treatments';
import AreaSelectSP from '@/components/ResponsiveAreaSelect.vue';
import Prefecture from '@/components/HomePrefecture.vue';
import Map from '@/components/HomeMap.vue';

interface AllPrefectures {
  [key: string]: Array<Prefectures>
}

interface Prefectures {
  name: string
  id: string
}

const allPrefectures: AllPrefectures = {
    "A1":[
        {"name":"福岡県","id":"1"},
        {"name":"佐賀県","id":"2"},
        {"name":"長崎県","id":"3"},
        {"name":"大分県","id":"4"},
        {"name":"熊本県","id":"5"},
        {"name":"宮崎県","id":"6"},
        {"name":"鹿児島県","id":"7"},
        {"name":"沖縄県","id":"8"}
    ],
    "A2":[
        {"name":"岡山県","id":"9"},
        {"name":"広島県","id":"10"},
        {"name":"山口県","id":"11"},
        {"name":"徳島県","id":"12"},
        {"name":"香川県","id":"13"},
        {"name":"愛媛県","id":"14"},
        {"name":"高知県","id":"15"}
    ],
    "A3":[
        {"name":"大阪府","id":"16"},
        {"name":"兵庫県","id":"17"},
        {"name":"京都府","id":"18"},
        {"name":"滋賀県","id":"19"},
        {"name":"奈良県","id":"20"},
        {"name":"和歌山県","id":"21"}
    ],
    "A4":[
        {"name":"東京都","id":"22"},
        {"name":"神奈川県","id":"23"},
        {"name":"埼玉県","id":"24"},
        {"name":"千葉県","id":"25"},
        {"name":"茨城県","id":"26"},
        {"name":"栃木県","id":"27"},
        {"name":"群馬県","id":"28"}
    ],
    "A5":[
        {"name":"愛知県","id":"29"},
        {"name":"岐阜県","id":"30"},
        {"name":"静岡県","id":"31"},
        {"name":"三重県","id":"32"},
        {"name":"新潟県","id":"33"},
        {"name":"富山県","id":"34"},
        {"name":"石川県","id":"35"},
        {"name":"福井県","id":"36"},
        {"name":"山梨県","id":"37"},
        {"name":"長野県","id":"38"}
    ],
    "A6":[
        {"name":"北海道","id":"39"},
        {"name":"青森県","id":"40"},
        {"name":"岩手県","id":"41"},
        {"name":"宮城県","id":"42"},
        {"name":"秋田県","id":"43"},
        {"name":"山形県","id":"44"},
        {"name":"福島県","id":"45"}
    ]
}

const emits = defineEmits<{
  (e: 'sendArea', data: string): void
  (e: 'sendTreatment', data: string): void
  (e: 'update:toggleArea', data: boolean): void
  (e: 'update:toggleTreatment', data: boolean): void
}>();

const props = defineProps({
  toggleArea: {
    type: Boolean,
    required: true,
  },
  toggleTreatment: {
    type: Boolean,
    required: true,
  },
})

const showAreaDialog = computed({
  get: () => props.toggleArea,
  set: (val) => emits('update:toggleArea', val)
})

const showTreatmentDialog = computed({
  get: () => props.toggleTreatment,
  set: (val) => emits('update:toggleTreatment', val)
})

const areaKey = ref("A4")
const changeAreaKey = (data: string) => {
  areaKey.value = data
}

function selectArea(val:string){
  emits('sendArea', val)
  emits('update:toggleArea', false)
}
function selectTreatment(val:string){
  emits('sendTreatment', val)
  emits('update:toggleTreatment', false)
}
</script>

<template>
  <v-dialog v-model="showAreaDialog" scrollable class="w-100" style="max-width: 1000px;">
    <v-card>
      <div class="w-100">
        <div class="d-flex flex-row justify-end">
          <v-btn @click="emits('update:toggleArea', false)" variant="text">X</v-btn>
        </div>
      </div>
      <v-divider class="mx-6"></v-divider>
      <div id="select-dialog-large">
        <v-row no-gutters class="mx-10 my-8">
          <v-col cols="12" md="6">
            <Map @send-area="changeAreaKey"></Map>
          </v-col>
          <v-col cols="12" md="6">
            <Prefecture use-for="select" :area-data="allPrefectures[areaKey]" @send-area="(val:string) => selectArea(val)" />
          </v-col>
        </v-row>
      </div>
      <div id="select-dialog-sp" class="mt-4" style="background-color: #F8F7F6;">
        <AreaSelectSP use-for="dialog" @send-area="(val:string) => selectArea(val)"/>
      </div>
    </v-card>
  </v-dialog>
    <!-- 施術選択 -->
    <v-dialog scrollable v-model="showTreatmentDialog" class="w-100" style="max-width: 1000px;">
      <v-btn @click="emits('update:toggleTreatment', false)" variant="text" class="d-flex justify-end font-weight-bold">X</v-btn>
      <v-card>
        <v-expansion-panels variant="accordion">
          <v-expansion-panel v-for="items in treatments">
            <v-expansion-panel-title static class="text-h6">{{ items.part }}</v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row id="select-dialog-large" no-gutters class="mx-9 my-3">
                <v-col @click="selectTreatment(treatment_name)" cols="6" v-for="treatment_name in items.lists" class="align-center my-2">
                  {{ treatment_name }}
                  <v-divider class="mr-16"></v-divider>
                </v-col>
              </v-row>
              <v-row id="select-dialog-sp">
                <v-col @click="selectTreatment(treatment_name)" cols="12" v-for="treatment_name in items.lists" 
                  class="align-center font-weight-bold text-body-2 px-3">
                  {{ treatment_name }}
                  <v-divider class=""></v-divider>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>
</template>
    
<style scoped>
#select-dialog-large{
  display: none;
}
#select-dialog-sp{
  display: flex;
}
@media (min-width:600px) {
  #select-dialog-large{
    display: flex;
  }
  #select-dialog-sp{
    display: none;
  }
}
</style>