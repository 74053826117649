<script setup lang="ts">
import { useDataStore } from '@/store/data';
import { useLoginStore } from '@/store/login';
import { ref, onMounted, watch } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import axios from 'axios';

import CommonSelectDialog from '@/components/ConsoleComponents/CommonSelectDialog.vue';
import ClinicBox from '@/components/CommonComponents/ClinicBox.vue';
import LoginDialog from '@/components/LoginRecommendDialog.vue'
import MainPagination from '@/components/MainPagination.vue';
import Loading from '@/components/CommonLoading.vue';

interface Clinic {
  id: number,
  name: string,
  access: string,
  dayoff: string,
  opentime: string,
  clinic_likes_count: number,
  clinic_records_count: number,
  send_clinic_image: string,
  calc_evaluation_average: number,
  related_records: Array<any>,
  treatment_data: Array<string>,
  tag_data: Array<string>,
  is_liked: boolean,
  city_id: number,
  prefecture_id: number,
  area_id: number,
  like_id: number | undefined
}

onMounted(async () => {
  window.scrollTo(0, 0);
  await getClinicData()
})

onBeforeRouteUpdate(async (to, _from, next) => {
  clinics.value = []
  nowLoading.value = true
  param.page = Number(to.query.page) || 1
  param.prefecture_id = to.query.pref || null
  param.city_id = to.query.city || null
  param.area_id = to.query.area || null
  await getClinicData();
  next()
})

const router = useRouter();
const route = useRoute();
const loginStore = useLoginStore();
const dataStore = useDataStore();

const problem = ref(route.params.problem);
const treatment = ref(route.params.treatment);
const clinics = ref<Array<Clinic>>([]);

const nowLoading = ref(false)
const empty = ref(false)
const totalPage = ref(1)

const toggleDialog = ref(false)
const showNarrow = ref(false)
const showDialogs = {
  prefectureSelect: ref(false),
  citySelect: ref(false),
  areaSelect: ref(false),
}
const cityData = ref([``])
const areaData = ref([``])

const narrowValue = {
  prefecture: ref(),
  city: ref(),
  area: ref()
}

const param = {
  prefecture_id: route.query.pref || null,
  city_id: route.query.city || null,
  area_id: route.query.area || null,
  page: Number(route.query.page) || 1,
}

watch(narrowValue.prefecture, (val) => {
  if(val){
    cityData.value = dataStore.getCitiesByPrefectureIdOrName(val).map(x => x.name)
  }
})
watch(narrowValue.city, (val) => {
  if(val){
    areaData.value = dataStore.getAreaByCityIdOrName(val).map(x => x.name)
  }
})
async function getClinicData(){
  nowLoading.value = true
  axios
    .get('/api/v1/clinics.json', {
      params: {
        q: {
          prefecture_id_eq: param.prefecture_id,
          city_id_eq: param.city_id,
          area_id_eq: param.area_id,
          treatments_id_eq: treatment.value,
        },
        page: param.page,
        per: 10,
        sort_key: '',
        order: 'DESC',
        uuid: loginStore.currentID
      }
    })
    .then(response => (clinics.value = response.data.data, totalPage.value = response.data.page))
    .finally(() => {
      if (!clinics.value[0]) { empty.value = true }
      nowLoading.value = false
    })
}

function executeSearch(){
  window.scrollTo(0, 0);
  clinics.value = []
  empty.value = false
  router.push({
    query: {
      page: 1,
      pref: dataStore.prefectures.find(x => x.name === narrowValue.prefecture.value)?.id,
      city: dataStore.cities.find(x => x.name === narrowValue.city.value)?.id,
      area: dataStore.areas.find(x => x.name === narrowValue.area.value)?.id
    }
  })
}

const changePage = (val:number) => {
  window.scrollTo(0, 0);
  clinics.value = []
  router.push({
    query: {
      page: val,
      pref: param.prefecture_id,
      city: param.city_id,
      area: param.area_id
    }
})}

function clearValue(){
  narrowValue.prefecture.value = ''
  narrowValue.city.value = ''
  narrowValue.area.value = ''
}
</script>

<template>

<div class="d-flex justify-center" style="min-height:600px;">
  <div class="mt-5 mx-0 w-100" style="max-width:1100px;">
    <div class="mx-2 my-2">
      <div class="font-weight-bold text-h6 mx-1 mr-10 text-black">
        {{ dataStore.getProblemNameById(Number(problem)) }}　>　{{ dataStore.getTreatmentNameById(Number(treatment)) }}
      </div>
    </div>
    <v-row no-gutters class="my-5 px-2 px-sm-4 w-100">
      <v-col cols="4">
        <v-btn @click="router.push(`/medical/prb/${problem}/${treatment}`)" variant="outlined" color="primary" class="rounded-e-0 rounded-s-lg font-weight-bold w-100" style="">トップ</v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn variant="text" class="rounded-0 text-white font-weight-bold w-100" style="background-color: #CCA8D2;">クリニック</v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn @click="router.push(`/medical/prb/${problem}/${treatment}/records`)" variant="outlined" color="primary" class="rounded-s-0 rounded-e-lg font-weight-bold w-100" style="">口コミ</v-btn>
      </v-col>
    </v-row>
    <v-card color="#BCBCBC" variant="outlined" flat rounded="0" class="pa-1 mt-3 mx-4">
      <v-btn variant="text" :append-icon="showNarrow ? 'mdi-chevron-down' : 'mdi-chevron-left'" @click="showNarrow = !showNarrow" class="d-flex justify-start text-h6 font-weight-bold text-no-wrap w-100">
        <div class="font-weight-bold text-body-1 mx-1 text-grey-darken-2">絞り込み</div>
      </v-btn>
      <div v-if="showNarrow">
        <v-row no-gutters class="ma-1 mx-sm-6">
          <v-col class="d-flex justify-center font-weight-bold align-center text-no-wrap" cols="3" sm="2" md="1">都道府県</v-col>
          <v-col class="my-1" cols="9" sm="4" md="3" @click="showDialogs.prefectureSelect.value = true">
            <v-text-field :value="narrowValue.prefecture.value" variant="solo-filled" flat rounded="lg" density="compact" single-line hide-details disabled></v-text-field>
          </v-col>
          <v-col class="d-flex justify-center font-weight-bold align-center text-no-wrap" cols="3" sm="2" md="1">市区町村</v-col>
          <v-col class="my-1" cols="9" sm="4" md="3" @click="narrowValue.prefecture.value ? showDialogs.citySelect.value = true : ''">
            <v-text-field :value="narrowValue.city.value" variant="solo-filled" flat rounded="lg" density="compact" single-line hide-details disabled></v-text-field>
          </v-col>
          <v-col class="d-flex justify-center font-weight-bold align-center text-no-wrap" cols="3" sm="2" md="1">エリア</v-col>
          <v-col class="my-1" cols="9" sm="4" md="3" @click="narrowValue.city.value ? showDialogs.areaSelect.value = true : ''">
            <v-text-field :value="narrowValue.area.value" variant="solo-filled" flat rounded="lg" density="compact" single-line hide-details disabled></v-text-field>
          </v-col>
          <v-col cols="12" class="my-3 d-flex justify-center">
            <v-btn color="grey" class="font-weight-bold px-6 mx-1" @click="clearValue" variant="tonal">クリア</v-btn>
            <v-btn color="primary" class="font-weight-bold px-6 mx-1" @click="executeSearch" variant="tonal">絞り込む</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <div class="my-10" v-if="nowLoading">
      <Loading />
    </div>
    <div class="my-10" v-else-if="empty">
      <p class="d-flex justify-center font-weight-bold text-h6">検索結果なし</p>
    </div>
    <div v-else>
      <v-row no-gutters class="w-100">
        <v-col cols="12" sm="6" md="4" lg="12" class="my-3 px-2 d-flex justify-center align-center" v-for="clinic in clinics" :key="clinic.id">
          <div><ClinicBox :clinic="clinic" @like-clinic="toggleDialog = !toggleDialog"></ClinicBox></div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center">
        <MainPagination :max-page="totalPage" :page="param.page" @before-page="changePage" @next-page="changePage"/>
      </div>
    </div>
  </div>
</div>

<CommonSelectDialog :multiple="false" :toggle="showDialogs.prefectureSelect.value" :list-data="dataStore.prefectureNames()" 
@update:toggle="(val) => showDialogs.prefectureSelect.value = val"
@send-val="(val) => narrowValue.prefecture.value = val[0]"/>
<CommonSelectDialog v-if="narrowValue.prefecture.value" :multiple="false" :toggle="showDialogs.citySelect.value" 
:list-data="cityData" 
@update:toggle="(val) => showDialogs.citySelect.value = val"
@send-val="(val) => narrowValue.city.value = val[0]"/>
<CommonSelectDialog v-if="narrowValue.city.value" :multiple="false" :toggle="showDialogs.areaSelect.value" 
:list-data="areaData" 
@update:toggle="(val) => showDialogs.areaSelect.value = val"
@send-val="(val) => narrowValue.area.value = val[0]"/>
<LoginDialog :toggle-dialog="toggleDialog" @update:toggle-dialog="(toggle:boolean) => toggleDialog = toggle" />
</template>