<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import { useDataStore } from '@/store/data';

interface AllPrefectures {
  [key: string]: Array<Prefectures>
}

interface regionNames {
  [key: string]: string
}

interface Prefectures {
  name: string
  id: string
}

const dataStore = useDataStore();

const regionNames:regionNames = {
  "A1": "九州・沖縄",
  "A2": "中国・四国",
  "A3": "関西",
  "A4": "関東",
  "A5": "中部",
  "A6": "東北・北海道"

}
const allPrefectures: AllPrefectures = {
    "A1":[
        {"name":"福岡県","id":"1"},
        {"name":"佐賀県","id":"2"},
        {"name":"長崎県","id":"3"},
        {"name":"大分県","id":"4"},
        {"name":"熊本県","id":"5"},
        {"name":"宮崎県","id":"6"},
        {"name":"鹿児島県","id":"7"},
        {"name":"沖縄県","id":"8"}
    ],
    "A2":[
        {"name":"岡山県","id":"9"},
        {"name":"広島県","id":"10"},
        {"name":"山口県","id":"11"},
        {"name":"徳島県","id":"12"},
        {"name":"香川県","id":"13"},
        {"name":"愛媛県","id":"14"},
        {"name":"高知県","id":"15"}
    ],
    "A3":[
        {"name":"大阪府","id":"16"},
        {"name":"兵庫県","id":"17"},
        {"name":"京都府","id":"18"},
        {"name":"滋賀県","id":"19"},
        {"name":"奈良県","id":"20"},
        {"name":"和歌山県","id":"21"}
    ],
    "A4":[
        {"name":"東京都","id":"22"},
        {"name":"神奈川県","id":"23"},
        {"name":"埼玉県","id":"24"},
        {"name":"千葉県","id":"25"},
        {"name":"茨城県","id":"26"},
        {"name":"栃木県","id":"27"},
        {"name":"群馬県","id":"28"}
    ],
    "A5":[
        {"name":"愛知県","id":"29"},
        {"name":"岐阜県","id":"30"},
        {"name":"静岡県","id":"31"},
        {"name":"三重県","id":"32"},
        {"name":"新潟県","id":"33"},
        {"name":"富山県","id":"34"},
        {"name":"石川県","id":"35"},
        {"name":"福井県","id":"36"},
        {"name":"山梨県","id":"37"},
        {"name":"長野県","id":"38"}
    ],
    "A6":[
        {"name":"北海道","id":"39"},
        {"name":"青森県","id":"40"},
        {"name":"岩手県","id":"41"},
        {"name":"宮城県","id":"42"},
        {"name":"秋田県","id":"43"},
        {"name":"山形県","id":"44"},
        {"name":"福島県","id":"45"}
    ]
}

const props = defineProps({
  regionKey: {
    type: String,
    required: true,
  },
  useFor: {
    type: String,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'sendArea', data: string): void
}>();

const route = useRoute();
const router = useRouter();
const currentCateogry = route.path.split("/")[1];
function searchWithArea(areaName:string){
  if(props.useFor == "search"){
    const prefectureId = dataStore.prefectures.find((prefecture) => prefecture.name == areaName)?.id;
    router.push(`/${currentCateogry}/loc/${prefectureId}`);
  }else if(props.useFor == "select"){
    emits('sendArea', areaName)
  }
}
</script>

<template>
<div class="w-100 h-100">
  <div class="h-100 d-flex align-center">
    <div class="mx-10 mb-6">
      <div class="mb-6 d-flex text-white font-weight-bold text-h6">◉ {{ regionNames[regionKey] }}</div>
      <v-row>
        <v-col cols="6" v-for="item in allPrefectures[regionKey]">
          <v-btn @click="searchWithArea(item.name)" flat class="w-100 text-black">{{ item.name }}</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</div>
</template>