<script setup lang="ts">
import { PropType } from 'vue'
import ProgressReportParts from './ProgressReportParts.vue';

interface ProgressReport {
  date: string
  memo: string
  all_images: Array<string>
  pain: number
  scar: number
  swelling: number
  satisfaction: number
  translate_emoji: string
}

const props = defineProps({
    progressReport: {
        type: Object as PropType<ProgressReport>,
        required: true,
    },
    beforeDay: {
        type: String,
        required: true,
    },
})

function calcTermDay(date: string){
  const day1 = new Date(props.beforeDay.split('T')[0])
  const day2 = new Date(date)
  const termDay = Math.ceil((day2.getTime() - day1.getTime()) / (86400000))
  return termDay
}

</script>

<template>
<div id="progress-report-large">
  <div class="mb-2 py-1 pl-2 font-weight-bold text-left text-white rounded-e-xl mt-7" style="width:220px; background-color: #CCA8D2;">{{ calcTermDay(progressReport.date.split('T')[0]) }}日目 {{ progressReport.date.split('T')[0] }}</div>
  <div class="d-flex justify-center flex-column px-7 pt-5">
    <div class="pa-1">
      <v-slide-group show-arrows>
        <v-slide-group-item>
          <v-card v-for="(item, i) in progressReport.all_images" :key="i"
          height="300" width=300 color="primary" class="mx-1"><img :src="item" class="w-100 h-100" style="object-fit: cover;"></v-card>
        </v-slide-group-item> 
      </v-slide-group>
    </div>
  </div>
  <div class="d-flex justify-center flex-column text-left px-10" style="max-width: 900px;">
    <ProgressReportParts :parts="{'pain':progressReport.pain, 'scar':progressReport.scar, 'swelling':progressReport.swelling,
      'satisfaction':progressReport.satisfaction, 'emoji':progressReport.translate_emoji}"/>
  </div>
  <div class="px-7 pb-7">
    <div class="d-flex text-lg-body-1 text-md-body-2 pa-md-1 font-weight-bold my-1 ml-10 mt-5">
      <v-icon start icon="mdi-note-outline" size="small" class="pt-1 ml-1"></v-icon><p style="color:#CCA8D2">メモ</p>
    </div>
    <div class="rounded-lg mx-12 pa-4 text-left text-body-2 font-weight-bold" style="background-color: #F6F4F0;">
      {{ progressReport.memo  }}
    </div>
  </div>
</div>

<div id="progress-report-sp">
  <div class="mt-10 py-1 pl-2 font-weight-bold text-body-2 text-left text-white rounded-e-xl"
    style="width:180px; background-color: #CCA8D2;">
    {{ calcTermDay(progressReport.date.split('T')[0]) }}日目 {{ progressReport.date.split('T')[0] }}
  </div>
  <v-slide-group show-arrows class="mt-3 mx-1">
    <v-slide-group-item>
      <v-card v-for="_i in 4"
      height="95" width=95 color="primary" class="mx-1"><img src="https://fakeimg.pl/600x400?text=NO+IMAGE&font=bebas" class="w-100 h-100" style="object-fit: cover;"></v-card>
    </v-slide-group-item> 
  </v-slide-group>
  <ProgressReportParts 
  :parts="{'pain':progressReport.pain, 'scar':progressReport.scar, 'swelling':progressReport.swelling,
          'satisfaction':progressReport.satisfaction, 'emoji':progressReport.translate_emoji}"/>
  <div class="font-weight-bold text-primary text-body-2 mx-3 my-2">メモ</div>
  <div class="mx-3">
    <v-card class="rounded-lg pa-3 elevation-0 text-caption" style="background-color: #F6F4F0;">
      {{ progressReport.memo }}
    </v-card>
  </div>
</div>
</template>

<style scoped>
#progress-report-large{
  display: none;
}
#progress-report-sp{
  display: block;
}
@media (min-width:600px) {
  #progress-report-large{
    display: block;
  }
  #progress-report-sp{
    display: none;
  }
}
</style>