<script setup lang="ts">
import StarRating from 'vue-star-rating';
import { useRouter } from 'vue-router';
import { useLoginStore } from '@/store/login';
import axios from 'axios';

const props = defineProps({
  record: {
    type: Object,
    required: true,
  },
  mainColor: String
})

const loginStore = useLoginStore();
const emits = defineEmits<{
  (e: 'likeRecord'): void
}>();

const router = useRouter();
function moveRecordDetail(id:string){
  router.push(`/records/${id}`);
}

function likeRecord(){
  if (!loginStore.loggedIn){
    emits('likeRecord');
    return;
  }
  const id = props.record.id;
  if(props.record.is_liked){
    props.record.is_liked = false;
    props.record.record_likes_count -= 1;
    axios.delete(`/api/v1/record_likes/${props.record.like_id}.json`)
    .then((_res) => {
    })
  }else{
    props.record.is_liked = true;
    props.record.record_likes_count += 1;
    axios.post(`/api/v1/record_likes.json`, {
      record_id: id,
      user_id: loginStore.currentID
    })
    .then((res) => {
      props.record.like_id = res.data.data.id;
    })
  
  }
}
</script>

<template>
<v-card class="position-relative" elevation="0" rounded="lg" :color="mainColor" width="100%" style="border-color: #F8F7F6;">
  <div v-ripple.center @click="moveRecordDetail(record.id)">
    <div class="w-100" style="height:50%; max-height: 50%;">
      <img :src="record.before_image" style="object-fit: cover; width:50%; aspect-ratio: 1;">
      <img :src="record.after_image" style="object-fit: cover; width:50%; aspect-ratio: 1;">
    </div>
    <div class="ma-3">
      <div class="w-100 d-flex justify-start align-center" style="height:10%">
        <v-icon start icon="mdi-tag-outline" size="small" color="#9d8d8f"></v-icon>
        <v-chip v-for="item in record.treatment_data" variant="outlined" density="compact" color="primary" class="mx-1 text-body-2 overflow-hidden">{{ item }}</v-chip>
      </div>
      <div class="w-100 d-flex justify-start align-center mt-2" style="height:10%">
        <v-icon start icon="mdi-hospital-building" size="small" color="#9d8d8f"></v-icon><div class="font-weight-bold text-body-2">{{ record.clinic_name }}</div>
      </div>
      <div class="w-100 d-flex justify-start align-center mt-2" style="height:10%">
        <v-icon start icon="mdi-currency-jpy" size="small" color="#9d8d8f"></v-icon><div class="font-weight-bold text-body-2">{{ record.price }}円</div>
      </div>
      <div class="w-100 d-flex justify-start align-center mt-2" style="height:10%">
        <StarRating read-only :rating="record.calc_evaluation_average" :star-size=14 :increment="0.1"/>
        <v-icon class="ml-4 mr-1" size="17" color="#c9baa5" icon="mdi-comment-processing-outline"></v-icon>
        <div class="font-weight-bold text-caption">{{ record.record_comments_count }}件</div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-end position-absolute" style="right:0; top:30px;">
    <div class="d-flex flex-column justify-center align-center">
      <v-btn @click="likeRecord" class="mr-3 mt-n5" size="45" rounded="circle" style="z-index: 100;">
        <div class="d-flex flex-column">
          <v-icon color="red" class="pt-2" :icon="record.is_liked ? 'mdi-heart' : 'mdi-heart-outline'"></v-icon>
          <div class="text-caption text-grey">{{ record.record_likes_count }}</div>
        </div>
      </v-btn>
    </div>
  </div>
</v-card>
</template>