<script setup lang="ts">
import { useDataStore } from '@/store/data';
import { useLoginStore } from '@/store/login';
import { ref, onMounted } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import ClinicBox from '@/components/CommonComponents/ClinicBox.vue';
import MainPagination from '@/components/MainPagination.vue';
import Loading from '@/components/CommonLoading.vue';
import LoginDialog from '@/components/LoginRecommendDialog.vue'
import axios from 'axios';

interface Clinic {
  id: number,
  name: string,
  access: string,
  dayoff: string,
  opentime: string,
  clinic_likes_count: number,
  clinic_records_count: number,
  send_clinic_image: string,
  calc_evaluation_average: number,
  related_records: Array<any>,
  treatment_data: Array<string>,
  tag_data: Array<string>,
  is_liked: boolean,
  city_id: number,
  prefecture_id: number,
  area_id: number,
  like_id: number | undefined
}

onMounted(async () => {
  window.scrollTo(0, 0);
  await getClinicData()
})
onBeforeRouteUpdate(async (to, _from, next) => {
  clinics.value = []
  empty.value = false
  param.page = Number(to.query.page) || 1
  await getClinicData()
  next()
})

const dataStore = useDataStore();
const route = useRoute();
const router = useRouter();
const loginStore = useLoginStore();

const prefecture = route.params.prefecture;
const city = route.params.city;
const area = route.params.area;
const toggleDialog = ref(false)
const empty = ref(false)
const clinics = ref<Array<Clinic>>([]);
const totalPage = ref(0)
const param = {
  page: Number(route.query.page) || 1,
}

async function getClinicData(){
  axios
    .get('/api/v1/clinics.json', {
      params: {
        q: {
          area_id_eq: area,
        },
        page: param.page,
        per: 10,
        sort_key: '',
        order: 'DESC',
        uuid: loginStore.currentID
      }
    })
    .then(response => (clinics.value = response.data.data, totalPage.value = response.data.page))
    .finally(() => empty.value = true)
}
const changePage = (val:number) => {
  window.scrollTo(0, 0);
  clinics.value = []
  router.push({
    query: {
      page: val,
    }
})}
</script>

<template>

<div class="d-flex justify-center" style="min-height:600px;">
  <div class="mt-5 mx-0 w-100" style="max-width:1100px;">
    <div class="mx-2 my-6">
      <div class="ml-0 ml-sm-6 mb-3 font-weight-bold text-caption text-sm-body-2 text-md-body-1">
        {{ dataStore.getPrefectureNameById(Number(prefecture)) }}{{ dataStore.getCityNameByIdAndName(Number(city)) }}{{ dataStore.getAreaNameByIdAndName(Number(area)) }}のクリニック
      </div>
      <div v-if="!empty">
        <Loading class="mt-10" />
      </div>
      <v-row no-gutters class="w-100">
        <v-col cols="12" sm="6" md="4" lg="12" class="my-3 px-2 d-flex justify-center align-center" v-for="clinic in clinics" :key="clinic.id">
          <div><ClinicBox :clinic="clinic" @like-clinic="toggleDialog = !toggleDialog"></ClinicBox></div>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-center">
      <MainPagination :max-page="totalPage" :page="param.page" @before-page="changePage" @next-page="changePage"/>
    </div>
  </div>
</div>
<LoginDialog :toggle-dialog="toggleDialog" @update:toggle-dialog="(toggle:boolean) => toggleDialog = toggle" />
</template>