<script setup lang="ts">
import { useDataStore } from '@/store/data';
import { useLoginStore } from '@/store/login';
import { ref, Ref, onMounted } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import ClinicBox from '@/components/CommonComponents/ClinicBox.vue';
import MainPagination from '@/components/MainPagination.vue';
import Loading from '@/components/CommonLoading.vue';
import LoginDialog from '@/components/LoginRecommendDialog.vue'
import axios from 'axios';

interface Clinic {
  id: number,
  name: string,
  access: string,
  dayoff: string,
  opentime: string,
  clinic_likes_count: number,
  clinic_records_count: number,
  send_clinic_image: string,
  calc_evaluation_average: number,
  related_records: Array<any>,
  treatment_data: Array<string>,
  tag_data: Array<string>,
  is_liked: boolean,
  like_id: number | undefined
}
interface City {
  id: number,
  name: string,
  prefecture_id: number
}
interface Area {
  id: number,
  name: string,
  city_id: number,
  prefecture_id: number
}

window.scrollTo(0, 0);

onMounted(async () => {
  window.scrollTo(0, 0);
  await getClinicData();
})
onBeforeRouteUpdate(async (to, _from, next) => {
  clinics.value = []
  empty.value = false
  param.page = Number(to.query.page) || 1
  await getClinicData()
  next()
})


const dataStore = useDataStore();
const route = useRoute();
const router = useRouter();
const loginStore = useLoginStore();

const prefecture = route.params.prefecture;
const showCity = ref(false)
const showArea = ref(false)
const toggleDialog = ref(false)
const empty = ref(false)
const cityData: Ref<Array<City>> = ref(dataStore.getCitiesByPrefectureIdOrName(Number(prefecture)));
const areaData: Ref<Array<Area>> = ref(dataStore.getAreaByPrefectureId(Number(prefecture)));
const clinics = ref<Array<Clinic>>([]);
const totalPage = ref(0)
const param = {
  page: Number(route.query.page) || 1,
}

async function getClinicData(){
  axios
    .get('/api/v1/clinics.json', {
      params: {
        q: {
          prefecture_id_eq: prefecture,
        },
        page: param.page,
        per: 10,
        sort_key: '',
        order: 'DESC',
        uuid: loginStore.currentID
      }
    })
    .then(response => (clinics.value = response.data.data, totalPage.value = response.data.page))
    .finally(() => empty.value = true)
}

const changePage = (val:number) => {
  window.scrollTo(0, 0);
  clinics.value = []
  router.push({
    query: {
      page: val,
    }
  })
}
</script>

<template>

<div class="d-flex justify-center" style="min-height:600px;">
  <div class="mt-5 mx-0 w-100" style="max-width:1100px;">
    <v-card color="#BCBCBC" variant="outlined" flat rounded="0" class="pa-2 mt-3 mx-4">
      <v-btn variant="text" append-icon="mdi-chevron-down" @click="showCity = !showCity" class="d-flex justify-start text-h6 font-weight-bold text-no-wrap w-100">
        <div class="font-weight-bold text-h6 mx-1 mr-10 text-black">{{ dataStore.getPrefectureNameById(Number(prefecture)) }}の都市一覧</div>
      </v-btn>
      <v-row no-gutters class="text-grey-darken-1" v-if="showCity">
        <v-col cols="6" sm="4" md="2" v-for="city in cityData" :key="city.id">
          <v-btn @click="router.push(`/medical/loc/${prefecture}/${city.id}`)" variant="text" 
            class="d-flex justify-start text-caption text-sm-body-2 font-weight-bold text-no-wrap w-100">・{{ city.name }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card color="#BCBCBC" variant="outlined" flat rounded="0" class="pa-2 mt-2 mx-4">
      <v-btn variant="text" append-icon="mdi-chevron-down" @click="showArea = !showArea" class="d-flex justify-start text-h6 font-weight-bold text-no-wrap w-100">
        <div class="font-weight-bold text-body-1 text-black my-1 mr-14">{{ dataStore.getPrefectureNameById(Number(prefecture)) }}のエリア一覧</div>
      </v-btn>
      <v-row v-if="showArea" no-gutters class="text-grey-darken-1">
        <v-col cols="6" sm="4" md="2" v-for="area in areaData" :key="area.id">
          <v-btn @click="router.push(`/medical/loc/${prefecture}/${area.city_id}/${area.id}`)"
            variant="text" class="d-flex justify-start text-caption text-sm-body-2 font-weight-bold text-no-wrap w-100">・{{ area.name }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <div class="mx-2 my-6">
      <div class="ml-6 mb-3 font-weight-bold text-h6">{{ dataStore.getPrefectureNameById(Number(prefecture)) }}のクリニック</div>
      <div v-if="!empty">
        <Loading class="mt-10" />
      </div>
      <v-row no-gutters class="w-100">
        <v-col cols="12" sm="6" md="4" lg="12" class="my-3 px-2 d-flex justify-center align-center" v-for="clinic in clinics" :key="clinic.id">
          <div><ClinicBox :clinic="clinic" @like-clinic="toggleDialog = !toggleDialog"></ClinicBox></div>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-center">
      <MainPagination :max-page="totalPage" :page="param.page" @before-page="changePage" @next-page="changePage"/>
    </div>
  </div>
</div>
<LoginDialog :toggle-dialog="toggleDialog" @update:toggle-dialog="(toggle:boolean) => toggleDialog = toggle" />
</template>