import { createRouter, createWebHistory } from 'vue-router';
import TopPage from '@/views/TopPage.vue';

import CommonTop from '@/views/CommonViews/CommonTopView.vue';
import Area from '@/views/CommonViews/Area.vue';
import City from '@/views/CommonViews/City.vue';
import Prefecture from '@/views/CommonViews/Prefecture.vue';
import PartRecords from '@/views/CommonViews/PartRecords.vue';
import PartClinics from '@/views/CommonViews/PartClinics.vue';
import PartTreatmentClinics from '@/views/CommonViews/PartTreatmentClinics.vue';
import PartTreatmentRecords from '@/views/CommonViews/PartTreatmentRecords.vue';
import ProblemRecords from '@/views/CommonViews/ProblemRecords.vue';
import ProblemClinics from '@/views/CommonViews/ProblemClinics.vue';
import ProblemTreatmentClinics from '@/views/CommonViews/ProblemTreatmentClinics.vue';
import ProblemTreatmentRecords from '@/views/CommonViews/ProblemTreatmentRecords.vue';
import Records from '@/views/CommonViews/Records.vue';
import ClinicDetail from '@/views/CommonViews/ClinicDetail.vue';
import RecordDetail from '@/views/CommonViews/RecordDetail.vue';

import User from '@/views/UserViews/User.vue';

import LoginTemp from '@/views/LoginTemp.vue';

import MedicalTop from '@/views/Medical/MedicalTop.vue';
import LocationBox from '@/views/Medical/LocationBox.vue';
import ProblemBox from '@/views/Medical/ProblemBox.vue';
import PartBox from '@/views/Medical/PartBox.vue';


const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', name: 'top', component: TopPage },
    { path: '/login', name: 'login', component: LoginTemp },
    // 美容医療 
    { path: '/medical', name: 'med-top', component: MedicalTop},
    // 場所検索
    { path: '/medical/loc', name: 'med-loc', component: LocationBox, props:true,
      children:[
        { path: '', name: 'med-loc-root', component: Prefecture },
        { path: ':prefecture', name: 'med-loc-prefecture', component: Prefecture },
        { path: ':prefecture/:city', name: 'med-loc-city', component: City },
        { path: ':prefecture/:city/:area', name: 'med-loc-area', component: Area },
        { path: ':prefecture/:city/:area/:clinic_id', name: 'med-clinic-detail', component: ClinicDetail, props:true },
        { path: ':prefecture/:city/:area/:clinic_id/records', name: 'clinic-records', component: Records, props:true },
      ] 
    },
    // 悩み検索
    { path: '/medical/prb', name: 'med-prb', component: ProblemBox, props:true,
      children:[
        { path: '', name: 'med-prb-root', component: ProblemClinics },
        { path: ':problem', name: 'med-prb-problem', component: CommonTop },
        { path: ':problem/clinics', name: 'med-prb-problem-clinic', component: ProblemClinics },
        { path: ':problem/records', name: 'med-prb-problem-record', component: ProblemRecords },
        { path: ':problem/:treatment', name: 'med-prb-treatment', component: CommonTop },
        { path: ':problem/:treatment/clinics', name: 'med-prb-treatment-clinic', component: ProblemTreatmentClinics },
        { path: ':problem/:treatment/records', name: 'med-prb-treatment-record', component: ProblemTreatmentRecords },
      ]
    },
    // 部位検索
    { path: '/medical/prt', name: 'med-prt', component: PartBox, props:true,
      children:[
        { path: '', name: 'med-prt-root', component: PartClinics },
        { path: ':part', name: 'med-prt-part', component: CommonTop },
        { path: ':part/clinics', name: 'med-prt-part-clinic', component: PartClinics },
        { path: ':part/records', name: 'med-prt-part-record', component: PartRecords },
        { path: ':part/:treatment', name: 'med-prt-treatment', component: CommonTop },
        { path: ':part/:treatment/clinics', name: 'med-prt-treatment-clinic', component: PartTreatmentClinics },
        { path: ':part/:treatment/records', name: 'med-prt-treatment-record', component: PartTreatmentRecords },
      ]
    },
    // ユーザー
    { path: '/:userId', name: 'user', component: User, props: true },
    { path: '/:userId/:id', name: 'record-detail', component: RecordDetail, props: true },
  ],
});

export default router;