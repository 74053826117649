<script setup lang="ts">
import { ref,Ref, computed } from 'vue'

const emits = defineEmits<{
  (e: 'update:toggle', data: boolean): void
  (e: 'sendVal', data: Array<string>): void
}>();

const props = defineProps<{
  listData: Array<string>
  toggle: boolean
  multiple: boolean
}>()

const selected: Ref<Array<string>> = ref([])
const searchWord = ref('')
const narrowList: Ref<Array<string>> = ref(props.listData)

const showDialog = computed({
  get: () => props.toggle,
  set: (val) => emits('update:toggle', val)
})

function executeNarrow(){
  narrowList.value = props.listData.filter((item) => {
    return item.includes(searchWord.value)
  })
}

function clicked(val:string){
  if(selected.value.includes(val)){
    selected.value = selected.value.filter((item) => {
      return item !== val
    })
  }else{
    selected.value.push(val)
  }
  emits('sendVal', selected.value)
  if (!props.multiple) {
    selected.value = []
    emits('update:toggle', false)
  }
}

function setColor(val:string){
  if(selected.value.includes(val)){
    return {backgroundColor: '#CCA8D2', color: '#FFFFFF'}
  }else{
    return {backgroundColor: '#FFFFFF', color: '#000000'}
  }
}


</script>

<template>
<v-dialog style="max-width: 1000px;"
  scrollable v-model="showDialog" class="w-100">
  <v-card>
    <div class="d-flex mt-3 justify-center">
      <v-row no-gutters>
        <v-col cols="1">
          <v-btn @click="emits('update:toggle', false)" variant="text">X</v-btn>
        </v-col>
        <v-col cols="12" sm="10" class="d-flex flex-row justify-center align-center">
          <v-text-field placeholder="検索ワードを入力" v-model="searchWord" style="max-width:300px;" variant="outlined" flat rounded="lg" density="compact" single-line hide-details>
          </v-text-field>
          <v-btn @click="executeNarrow" class="ml-2" color="green" variant="tonal" >
            検索
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row no-gutters class="ma-1 pa-1">
      <v-col class="px-1" cols="12" md="6" v-for="item in narrowList">
        <v-btn @click="clicked(item)" class="w-100 text-caption text-sm-body-2" variant="text" :style="[setColor(item)]">{{ item }}</v-btn>
        <v-divider class="mx-10 mb-1"></v-divider>
      </v-col>
    </v-row>
  </v-card>
</v-dialog>
</template>