<script setup lang="ts">
import { ref, watch } from 'vue';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'

const route = useRoute()
const router = useRouter();
const sortedBy = ref({ label: '新着順', value: 'date' });
const param = {
  minPrice: route.query.minPrice || null,
  maxPrice: route.query.maxPrice || null,
  evaluation: route.query.evaluation || null,
}
const selectItems = ref([
    { label: '新着順', value: 'date' },
    { label: 'いいね順', value: 'likes' },
    { label: 'コメント数順', value: 'comments' },
    { label: '評価順', value: 'evaluation' },
    { label: '価格順', value: 'price' },
]);

onBeforeRouteUpdate((to, _from, next) => {
  param.minPrice = to.query.minPrice || null
  param.maxPrice = to.query.maxPrice || null
  param.evaluation = to.query.evaluation || null
  next()
})

watch(sortedBy, (newSortedBy) => {
    // 空でなければ並び替え処理が発生
    if(newSortedBy.value) {
      router.push({
        query: {
          minPrice: param.minPrice,
          maxPrice: param.maxPrice,
          evaluation: param.evaluation,
          sortedBy: newSortedBy.value,
        }
      })
    }
});
</script>

<template>
<v-row align-center >
    <v-col class="d-flex align-center justify-end">  
        <div class="font-weight-bold pr-3">並び替え</div>
        <v-select hide-details clearable variant="outlined" density="compact" return-object
            base-color="#CCA8D2" color="#CCA8D2" style="max-width: 230px;"
            v-model="sortedBy" :items="selectItems" item-title="label" item-value="value"
        ></v-select>
    </v-col>
</v-row>
</template>
