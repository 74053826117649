import 'vuetify/styles'
import { createVuetify, type ThemeDefinition } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

const CustomDarkTheme: ThemeDefinition = {
  dark: true,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#CCA8D2',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
}

export const vuetifyPlugin = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi', // default - only for display purposes
  },
  theme: {
    defaultTheme: 'CustomDarkTheme',
    themes: {
      CustomDarkTheme,
    },
  },
})