<script setup lang="ts">
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import { useLoginStore } from '@/store/login'
import { useRouter } from 'vue-router'
import { onBeforeMount, ref } from 'vue'
import axios from 'axios'

const loginStore = useLoginStore()
const inputRule = (v:string) => !!v || '必須';
const router = useRouter()
const loginParams = ref({'password': '', 'email': ''})
onBeforeMount(() => {
  if (loginStore.loggedIn) {
    router.push('/mypage')
  }
})

function login(){
  if (loginParams.value.email && loginParams.value.password) {
    axios.post('/api/v1/account.json', loginParams.value)
    .then((res) => {
      if(res.data.status === 'ok'){
        loginStore.login(res.data.data.id, res.data.data.name)
        router.push('/mypage')
      } else {
        alert('メールアドレスかパスワードが間違っています')
      }
    })
  }
}

</script>

<template>
<MainHeader />
  <div style="min-height:500px;">
    <div class="mt-8 mb-4 d-flex justify-center">
      <div class="text-body-1 text-sm-h6">仮のログイン画面</div>
    </div>
    <div >
      <v-form class="d-flex flex-column align-center">
        <v-text-field class="w-100 px-4 pt-1" style="max-width: 600px;" variant="outlined" v-model="loginParams.email" single-line :rules="[inputRule]"
          density="compact" type="email" autocomplete="username" placeholder="email">
        </v-text-field>
        <v-text-field class="w-100 px-4 pt-1" style="max-width: 600px;" variant="outlined" v-model="loginParams.password" single-line :rules="[inputRule]"
          density="compact" type="password" autocomplete="current-password" placeholder="password">
        </v-text-field>
      </v-form>
    </div>
    <div class="d-flex justify-center">
      <v-btn @click="login" variant="text" class="text-white font-weight-bold" style="background-color: #CCA8D2;">
        ログイン
      </v-btn>
    </div>
  </div>
<MainFooter main-color="f6f4f0"/>
</template>