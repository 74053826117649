<script setup lang="ts">
</script>

<template>
<div class="d-flex justify-center position-relative">
  <img src="@/assets/images/bg-left.png" class="position-absolute" style="width: 10%; max-width: 300px; left:0; top:50px;">
  <div id="homelogo-large" class="w-100 mt-12" style="max-height:380px; max-width: 1200px;">
    <v-row no-gutters>
      <v-col>
          <div class="ma-6 ma-md-12 font-weight-bold d-flex flex-column">
            <div class="text-caption text-sm-body-2 text-md-h6 font-weight-bold" style="color:#333333; line-height: 1.5;">口 コ ミ か ら<br>自 分 に あ っ た 美 容 医 療 を 探 せ る</div>
            <div class="text-caption text-sm-body-1 text-md-h6 font-weight-bold mt-md-2 mt-lg-4" style="color:#DACAB2;">美レコで記録して経過をシェア</div>
            <div class="text-caption text-sm-body-2 text-md-body-h6 font-weight-bold mt-md-2 mt-lg-4" style="color:#333333; max-width:520px;">美レコでは、美容医療の経過を簡単に記録しみんなとシェアすることができます。<br>偽りのない口コミで美容医療を受ける際の悩みや不安を解消！</div>
          </div>
      </v-col>
      <v-col class="d-flex justify-center">
        <img src="@/assets/images/iPhone.png" style="width:70% ;max-height:380px; ">
      </v-col>
    </v-row>
    <img src="@/assets/images/bg-right.png" class="position-absolute" style="width: 10%; max-width: 300px; right:0; top:50px;">
  </div>

  <div id="homelogo-sp" class="w-100 h-auto">
    <v-row class="w-100">
      <v-col cols="7">
        <div class="my-2 ml-2 font-weight-bold d-flex flex-column">
          <div class="" style="color:#333333; line-height: 1.5; font-size:0.9rem">口コミから自分にあった美容医療を探せる</div>
          <div class="text-caption text-no-wrap font-weight-bold" style="color:#DACAB2;">美レコで記録して経過をシェア</div>
          <div class="font-weight-bold" style="color:#333333; max-width:520px; line-height:1.7; font-size:0.5rem">
            美レコでは、美容医療の経過を簡単に記録しみんなとシェアすることができます。<br>偽りのない口コミで美容医療を受ける際の悩みや不安を解消！
          </div>
        </div>
      </v-col>
      <v-col cols="5">
        <img src="@/assets/images/iPhone.png" style="width:100%; ">
      </v-col>
    </v-row>
  </div>
</div>
</template>

<style scoped>
#homelogo-large{
  display: none;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(236, 218, 238, 0.67) 0%, rgba(254, 237, 254, 0.41) 56.77%, rgba(250, 229, 248, 0.14) 100%);
}
#homelogo-sp{
  display: block;
  background: linear-gradient(180deg, rgba(236, 218, 238, 0.67) 0%, rgba(254, 237, 254, 0.41) 56.77%, rgba(255, 245, 254, 0.14) 100%);

}
@media (min-width:600px) {
  #homelogo-large{
    display: block;
  }
  #homelogo-sp{
    display: none;
  }
}
</style>