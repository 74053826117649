<script setup lang="ts">
import { useRouter } from 'vue-router';
const router = useRouter();

const props = defineProps<{
  mainColor: string
}>();

function moveTo(link:string){
  window.scrollTo(0, 0);
  router.push(link);
}
</script>

<template>
<div id="footer-large" class="w-100" style="height:auto; min-height: 560px;" :style="{ backgroundColor: '#' + props.mainColor }">
    <v-row no-gutters class="py-10 w-100">
      <v-col cols="2"></v-col>
      <v-col cols="2">
        <div class="d-flex flex-column align-start font-weight-bold">
          <div class="my-1" style="color:#CCA8D2;">コンテンツを探す</div>
          <v-btn @click="moveTo('/records')" variant="text" class="font-weight-bold text-body-1">口コミ</v-btn>
          <v-btn @click="moveTo('/clinics')" variant="text" class="font-weight-bold text-body-1">クリニック</v-btn>
          <v-btn href="http://articles.b-rec.com/" variant="text" class="font-weight-bold text-body-1">美容記事</v-btn>
        </div>
      </v-col>
      <!-- <v-col cols="2" class="ml-4">
        <div class="d-flex flex-column align-start font-weight-bold">
          <div class="my-1" style="color:#CCA8D2">私たちについて</div>
          <div class="my-1">美レコとは</div>
          <div class="my-1">誕生時のお話</div>
        </div>
      </v-col> -->
      <!-- <v-col cols="2" class="ml-4">
        <div class="d-flex flex-column align-start font-weight-bold">
          <div @click="moveTo('/mypage')" class="my-1" style="color:#CCA8D2">マイページ</div>
          <div class="my-1">会員登録</div>
          <div @click="moveTo('/login')" class="my-1">ログイン</div>
          <div class="my-1">ヘルプ</div>
        </div>
      </v-col> -->
      <v-col cols="4"> </v-col>
    </v-row>
    <v-row no-gutters class="w-100 pt-6">
      <v-col cols="2"> </v-col>
      <v-col cols="3">
        <a href="#"><img src="@/assets/images/logo.png" style="width: 100%; max-width: 180px;"></a>
      </v-col>
      <v-col cols="7"> </v-col>
    </v-row>
    <v-row class="w-100">
      <v-col cols="2"></v-col>
      <v-col cols="6">
        <div class="font-weight-bold text-sm-caption text-md-body-2 text-lg-body-1" style="line-height: normal;">美レコ はクリニック、施術選びから施術後の効果可視化まで全て管理ができるアプリです。<br>
          他の人の口コミから自分にあったクリニックや施術が見つかる！<br>
          写真や施術情報を簡単入力するだけで今までの美容履歴がまとまる！</div>
      </v-col>
      <v-col cols="2">
        <a href="#"><img src="@/assets/images/badge_apple.png" style="width: 100%; max-width: 180px;"></a>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
  <div class="py-8"><v-divider></v-divider></div>
  <div>
    <v-row class="w-100 d-flex align-center justify-center">
      <v-col class="d-flex justify-center">
        <div class="d-flex flex-row">
          <v-btn href="https://lp.b-rec.com/terms" variant="plain">利用規約</v-btn>
          <v-btn href="https://vantageit.co.jp/privacy/" variant="plain">プライバシーポリシー</v-btn>
          <v-btn href="https://lp.b-rec.com/contact" variant="plain">お問い合わせ</v-btn>
          <v-btn href="https://vantageit.co.jp/" variant="plain">会社概要</v-btn>
        </div>
      </v-col>
      <v-col>
        <div class="text-caption">COPYRIGHT © bireko ALL RIGHTS RESERVED.</div>
      </v-col>
    </v-row>
  </div>
</div>

<div id="footer-sp" :style="{ backgroundColor: '#' + props.mainColor }">
  <div class="w-100 py-5">
    <div class="d-flex flex-column justify-center">
      <v-btn href="https://lp.b-rec.com/terms" variant="text" class="text-grey font-weight-bold pa-0" >利用規約</v-btn>
      <v-btn href="https://vantageit.co.jp/privacy/" variant="text" class="text-grey font-weight-bold pa-0" >プライバシーポリシー</v-btn>
      <v-btn href="https://lp.b-rec.com/contact" variant="text" class="text-grey font-weight-bold pa-0" >お問い合わせ</v-btn>
      <v-btn href="https://vantageit.co.jp/" variant="text" class="text-grey font-weight-bold pa-0" >会社概要</v-btn>
    </div>
    <div class="my-1 d-flex justify-center text-grey" style="font-size:0.75rem">COPYRIGHT © b-rec.com ALL RIGHTS RESERVED.</div>
  </div>
</div>
</template>

<style scoped>
#footer-large{
  display: none;
}
#footer-sp{
  display: block;
}
@media (min-width:800px) {
  #footer-large{
    display: block;
  }
  #footer-sp{
    display: none;
  }
}
</style>