<script setup lang="ts">
import axios from 'axios';
import { ref, onMounted} from 'vue';
import { useLoginStore } from '@/store/login';

import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
import HomeFab from '@/components/TopPageComponents/HomeFab.vue';
import ProblemsBox from '@/components/TopPageComponents/ProblemsBox.vue';
import AreaBox from '@/components/TopPageComponents/AreaBox.vue';
import RecordBox from '@/components/CommonComponents/RecordBox.vue';


interface Record {

}

const loginStore = useLoginStore();
const testProblems = [
  {name: '美容整形', items:['エイジング', '離れ目・寄り目', '涙袋', 'フォトフェイシャル', '鼻先', '小顔', 'フィイスライン', 'タレ目・つり目']},
  {name: '美容皮膚科', items:['ハリ・ツヤ', '毛穴', '乾燥肌', 'ニキビ・ニキビ跡', 'シワ・たるみ（肌）', 'ほうれい線', '脂性肌']},
  {name: '美容歯科', items:['歯並び', 'その他（歯）', '歯の白さ']},
  {name: 'その他医療', items:['疲労回復', 'その他（その他）']},
]
const records = ref<Array<Record>>([])

onMounted(() => {
  axios
    .get('/api/v1/records.json', {
      params: {
        page: 1,
        per: 3,
        sort_key: 'likes',
        order: 'DESC',
        uuid: loginStore.currentID
      }
    })
    .then(response => (records.value = response.data.data))
})
</script>

<template>
<MainHeader />

<div class="d-flex justify-center">
  <div class="mt-10 mx-6 w-100" style="max-width:1100px;">
    <div class="d-flex justify-start align-center">
      <div class="rounded-xl" style="height:41px; width:5px; background-color: #DACABF;"></div>
      <div class="ml-3 font-weight-bold text-h6">部位・施術から探す</div>
    </div>
  </div>
</div>
<HomeFab />

<div class="d-flex justify-center">
  <div class="mb-15 mx-6 w-100" style="max-width:1100px;">
    <div class="mt-10 d-flex justify-start align-center">
      <div class="rounded-xl" style="height:41px; width:5px; background-color: #DACABF;"></div>
      <div class="ml-3 font-weight-bold text-h6">悩みから探す</div>
    </div>
    <ProblemsBox :problems="item" v-for="item in testProblems" class="mt-5" />
    <div class="mt-15 d-flex justify-start align-center">
      <div class="rounded-xl" style="height:41px; width:5px; background-color: #DACABF;"></div>
      <div class="ml-3 font-weight-bold text-h6">エリアから探す</div>
    </div>
    <AreaBox class="mt-5" />
    <!-- <div class="mt-15 d-flex justify-start align-center">
      <div class="rounded-xl" style="height:41px; width:5px; background-color: #DACABF;"></div>
      <div class="ml-3 font-weight-bold text-h6">みんなの記録</div>
    </div>
    <RecordBox :record="record" v-for="record in records" class="mt-5"/>
    <div class="my-15 d-flex justify-center" >
      <v-btn flat class="w-100 py-6 d-flex align-center text-white font-weight-bold rounded-pill" style="background-color: #CCA8D2; max-width: 350px;">みんなの経過写真をもっと見る ▶︎</v-btn>
    </div>
    <div class="mt-15 d-flex justify-start align-center">
      <div class="rounded-xl" style="height:41px; width:5px; background-color: #DACABF;"></div>
      <div class="ml-3 font-weight-bold text-h6">美容コラム</div>
    </div> -->
  </div>
</div>

<MainFooter main-color="ede9e2"/>
</template>