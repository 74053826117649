<script setup lang="ts">
import axios from 'axios';
import { useLoginStore } from '@/store/login';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { ref, onMounted } from 'vue';

import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
import RecordBox from '@/components/CommonComponents/RecordBox.vue';
import LoginDialog from '@/components/LoginRecommendDialog.vue'
import MainPagination from '@/components/MainPagination.vue';
import Loading from '@/components/CommonLoading.vue';

const props = defineProps({
  userId: {
    type: String,
    required: true,
  },
})

onMounted(async () => {
  window.scrollTo(0, 0);
  await getRecords()
})

onBeforeRouteUpdate(async (to, _from, next) => {
  records.value = []
  nowLoading.value = true
  param.page = Number(to.query.page) || 1
  await getRecords();
  next()
})

const router = useRouter();
const route = useRoute();
const loginStore = useLoginStore();

const toggleDialog = ref(false)
const records = ref([])
const nowLoading = ref(false)
const empty = ref(false)
const totalPage = ref(1)
const param = {
  page: Number(route.query.page) || 1,
}


function getRecords(){
  nowLoading.value = true
  axios.get('/api/v1/records.json', {
    params: {
      page: param.page,
      per: 10,
      order: "DESC",
      uuid: loginStore.currentID,
      q: {
        report_folder_user_id_eq: props.userId
      }
    }
  })
  .then(response => (records.value = response.data.data, totalPage.value = response.data.page))
  .finally(() => {
    if (!records.value[0]) { empty.value = true }
    nowLoading.value = false
  })
}

const changePage = (val:number) => {
  window.scrollTo(0, 0);
  records.value = []
  router.push({
    query: {
      page: val,
    }
})}
</script>

<template>
<MainHeader />

<div class="d-flex justify-center" style="min-height:600px;">
  <div class="mt-5 mx-0 w-100" style="max-width:1100px;">
    <div class="my-10" v-if="nowLoading">
      <Loading />
    </div>
    <div class="my-10" v-else-if="empty">
      <p class="d-flex justify-center font-weight-bold text-h6">検索結果なし</p>
    </div>
    <div v-else>
      <v-row no-gutters class="pb-10">
        <v-col cols="12" sm="6" md="12" class="my-4 px-4" v-for="record in records" >
          <RecordBox :record="record" @like-record="toggleDialog = true"/>
        </v-col>
      </v-row>
      <div class="d-flex justify-center">
        <MainPagination :max-page="totalPage" :page="param.page" @before-page="changePage" @next-page="changePage"/>
      </div>
    </div>
  </div>
</div>
<MainFooter main-color="ede9e2"/>
<LoginDialog :toggle-dialog="toggleDialog" @update:toggle-dialog="(toggle:boolean) => toggleDialog = toggle" />
</template>

<style scoped>
</style>
