<script setup lang="ts">
import { computed } from 'vue'

const emits = defineEmits<{
  (e: 'update:toggleDialog', data: boolean): void
}>();

const props = defineProps({
  toggleDialog: {
    type: Boolean,
    required: true,
  }
})

const showDialog = computed({
  get: () => props.toggleDialog,
  set: (val) => emits('update:toggleDialog', val)
})
</script>

<template>
  <v-dialog v-model="showDialog" max-width="640">
    <v-card class="pa-5" rounded="lg" variant="flat" style="border-width: 1px; border-color: #DACAB2; max-width: 640px;">
      <div class="font-weight-bold text-lg-h6 text-center">ログインすると気になった記録(口コミ)をお気に入りして保存することができます！</div>
      <v-row no-gutters class="my-lg-7 my-md-7 my-2">
        <v-col cols="6" class="d-flex justify-end">
          <v-btn color="#DACAB2" class="rounded-s-lg rounded-e-0 text-white font-weight-bold elevation-0" id="loginDialog">ログイン</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn color="grey" variant="outlined" class="rounded-e-lg rounded-s-0 text-white font-weight-bold elevation-0" id="loginDialog">新規登録</v-btn>
        </v-col>
        <v-col cols=12 class="d-flex justify-center mt-lg-6 mt-1">
          <div id="loginDialog">
            <v-form>
              <div class="font-weight-bold text-body-2 mt-4 mb-2">メールアドレス</div>
              <v-text-field density="compact" hide-details bg-color="#ffffff" rounded="lg" class="w-100" type="email"
                single-line variant="outlined" base-color="#DACAB2" color="#DACAB2" placeholder="be-rec@example.com" autocomplete="email" >
              </v-text-field>
              <div class="font-weight-bold text-body-2 mt-3 mb-2">パスワード</div>
              <v-text-field density="compact" hide-details bg-color="#ffffff" rounded="lg" class="w-100" type="password"
                single-line variant="outlined" base-color="#DACAB2" color="#DACAB2" placeholder="password" autocomplete="current-password">
              </v-text-field>
            </v-form>
          </div>
        </v-col>
        <v-col class="d-flex flex-column align-center mt-5">
          <v-btn width="110" style="background-color: #CCA8D2;" class="my-lg-5 my-md-5 my-1 rounded-lg text-white font-weight-bold elevation-0 px-6">ログイン</v-btn>
          <v-btn id="loginDialog" variant="outlined" rounded="lg" style="border-color: #DACAB2;" 
                  class="mt-3 font-weight-bold text-caption">
            <template v-slot:prepend><img src="@/assets/images/104490_apple_icon.png" width="25"></template>
            Apple IDでログイン　
          </v-btn>
          <v-btn id="loginDialog" variant="outlined" rounded="lg" style="border-color: #DACAB2;" 
                  class="mt-3 font-weight-bold text-caption">
            <template v-slot:prepend><img src="@/assets/images/LINE_APP_Android.png" width="25" height="25"></template>
            　LINEでログイン　　
          </v-btn>
          <v-btn id="loginDialog" variant="outlined" rounded="lg" style="border-color: #DACAB2;" 
                  class="mt-3 font-weight-bold text-caption">
            <template v-slot:prepend><img src="@/assets/images/2021_Facebook_icon.png" width="25" height="25"></template>
            Facebookでログイン
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<style scoped>
#loginDialog {
  width: 100%;
}

@media (min-width:600px) {
  #loginDialog {
    width: 75%;
  }
}
</style>