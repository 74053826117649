<script setup lang="ts">
import Header from '@/components/MainHeader.vue';
import Footer from '@/components/MainFooter.vue';
import SearchPanel from '@/components/RecordSearchPanel.vue';
import SortSelects from '@/components/MainSortSelects.vue';
import RecordBox from '@/components/MainRecordBox.vue';
import Pagination from '@/components/MainPagination.vue';
import Loading from '@/components/CommonLoading.vue';
import MiniRecordBox from '@/components/ResponsiveMiniRecordBox.vue';
import SortRecords from '@/components/ResponsiveRecordSort.vue';
import LoginDialog from '@/components/LoginRecommendDialog.vue'
import { useLoginStore } from '@/store/login';
import axios from 'axios';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { ref, onMounted } from 'vue';

interface Record {

}

onMounted(() => {
  window.scrollTo(0, 0);
  getRecordsData()
})

onBeforeRouteUpdate((to, _from, next) => {
  empty.value = false
  records.value = []

  param.page = Number(to.query.page) || 1
  param.minPrice = to.query.minPrice || null
  param.maxPrice = to.query.maxPrice || null
  param.evaluation = to.query.evaluation || null
  param.sortedBy = to.query.sortedBy || 'date'
  param.treatment = to.query.treatment || null
  getRecordsData()
  next()
})

const toggleDialog = ref(false)
const loginStore = useLoginStore();
const route = useRoute()
const router = useRouter();
const empty = ref(false)
const records = ref<Array<Record>>([])
const totalPage = ref(0)
const param = {
  treatment: route.query.treatment || null,
  minPrice: route.query.minPrice || null,
  maxPrice: route.query.maxPrice || null,
  evaluation: route.query.evaluation || null,
  page: Number(route.query.page) || 1,
  sortedBy: route.query.sortedBy || 'date',
}

const changePage = (val:number) => {
  window.scrollTo(0, 0); // ページ変更時、トップに移動
  records.value = []

  router.push({
    query: {
      treatment: param.treatment,
      maxPrice: param.maxPrice,
      minPrice: param.minPrice,
      evaluation: param.evaluation,
      page: val,
      sortedBy: param.sortedBy,
    }
  })
}

function getRecordsData() {
  axios
    .get('/api/v1/records.json', {
      params: {
        q: {
          evaluation_average_gteq: param.evaluation,
          price_gteq: param.minPrice,
          price_lteq: param.maxPrice,
          treatments_name_eq: param.treatment
        },
        page: param.page,
        per: 10,
        sort_key: param.sortedBy,
        order: 'DESC',
        uuid: loginStore.currentID
      }
    })
    .then(response => (records.value = response.data.data, totalPage.value = response.data.page))
    .finally(() => {
      if (!records.value[0]) {
        empty.value = true
      }
    })
}

</script>

<template>
<Header />
<div class="d-flex justify-center" style="min-height:1500px;">
  <div id="records-large" class="w-75">
    <div class="d-flex justify-start">
      <div class="font-weight-bold ml-n4 py-10 text-h6">みんなの記録(口コミ)</div>
    </div>
    <div class="d-flex justify-center pb-8">
      <SearchPanel />
    </div>
    <div v-if="!empty" class="d-flex justify-end pb-8">
      <SortSelects />
    </div>
    <div v-if="records[0] || empty" class="d-flex justify-center">
      <div v-if="!empty" class="d-flex flex-column">
        <RecordBox  v-for="(record, index) in records" :key="index" :record="record" @like-record="toggleDialog = !toggleDialog"></RecordBox>
      </div>
      <div v-else>
        <div class="font-weight-bold">検索結果なし</div>
      </div>
    </div>
    <div v-else class="w-100">
      <Loading />
    </div>
    <div v-if="records[0]" class="d-flex justify-center mb-12">
      <div class="text-center">
        <Pagination :max-page="totalPage" :page="param.page" @next-page="changePage" @before-page="changePage"></Pagination>
      </div>
    </div>
  </div>

  <div id="records-sp" class="w-100">
    <div class="d-flex flex-row mt-2 ml-5">
      <p class="font-weight-bold">記録(口コミ)</p>
    </div>
    <div class="pt-4">
      <div v-if="records[0] || empty">
        <div class="mx-9">
          <SortRecords />
          <MiniRecordBox :main-color="'#f6f4f0'" class="mt-6" :record="item" v-for="item in records" @like-record="toggleDialog = !toggleDialog"/>
        </div>
        <div v-if="records[0]" class="d-flex justify-center mb-12">
          <div class="text-center">
            <Pagination class="w-100" :page="param.page" :max-page="totalPage" @before-page="changePage" @next-page="changePage" />
          </div>
        </div>
      </div>
      <div v-else>
        <Loading />
      </div>
    </div>
  </div>
</div>
<Footer main-color="f6f4f0" />
<LoginDialog :toggle-dialog="toggleDialog" @update:toggle-dialog="(toggle:boolean) => toggleDialog = toggle" />
</template>

<style scoped>
#records-large{
  display: none;
}
#records-sp{
  display: block;
}
@media (min-width:600px) {
  #records-large{
    display: block;
  }
  #records-sp{
    display: none;
  }
}
</style>