<script setup lang="ts">
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

defineProps({
  prefecture: {
    type: String,
    required: true,
  },
  city: {
    type: String,
    required: false,
  },
  area: {
    type: String,
    required: false,
  },
  clinic_id: {
    type: String,
    required: false,
  },
})

window.scrollTo(0, 0);
</script>

<template>
<MainHeader />
<router-view></router-view>
<MainFooter main-color="ede9e2"/>
</template>