<script setup lang="ts">
import { computed } from 'vue'
const props = defineProps<{
    page: number
    maxPage: number
}>();   

const emits = defineEmits<{
    (e: 'nextPage', page: number): void
    (e: 'beforePage', page: number): void
}>();

const isLastPage = computed(() => props.page === props.maxPage)
const isFirstPage = computed(() => props.page === 1)

function beforePage() {
  emits('nextPage', props.page - 1)
}

function nextPage() {
  emits('beforePage', props.page + 1)
}

function lastPage(){
  emits('nextPage', props.maxPage)
}

function firstPage(){
  emits('beforePage', 1)
}
</script>

<template>
<div class="d-flex justify-center align-center" style="width:400px; height:50px;">
  <v-btn id="pagination-button" @click="firstPage" icon="mdi-chevron-double-left" variant="plain" :disabled="isFirstPage"></v-btn>
  <v-btn id="pagination-button" class="mx-2" @click="beforePage" icon="mdi-chevron-left" variant="plain" :disabled="isFirstPage"></v-btn>
  <div>{{ page }}/{{ maxPage }}</div>
  <v-btn id="pagination-button" class="mx-2" @click="nextPage" icon="mdi-chevron-right" variant="plain" :disabled="isLastPage"></v-btn>
  <v-btn id="pagination-button" @click="lastPage" icon="mdi-chevron-double-right" variant="plain" :disabled="isLastPage"></v-btn>
</div>
</template>

<style scoped>
#pagination-button{
  max-width: 16px;
}
</style>