<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useDataStore } from '@/store/data';
import axios from 'axios';

onMounted(() => {
  getColumn()
})

const route = useRoute();
const router = useRouter();
const dataStore = useDataStore();
const path = computed(() => {
  if(route.path.endsWith('/')){
    return route.path
  }else{
    return route.path + '/'
  }
})
const breadClumbs = computed(() => {
  // 部位系
  if(!problem.value){
    if(!treatment.value){
      return `${dataStore.getPartNameById(Number(part.value))}`
    }else{
      return `${dataStore.getPartNameById(Number(part.value))}　>　${dataStore.getTreatmentNameById(Number(treatment.value))}`
    }
  // 悩み系
  }else{
    if(!treatment.value){
      return `${dataStore.getProblemNameById(Number(problem.value))}`
    }else{
      return `${dataStore.getProblemNameById(Number(problem.value))}　>　${dataStore.getTreatmentNameById(Number(treatment.value))}`
    }
  }
})
const problem = ref(route.params.problem);
const part = ref(route.params.part);
const treatment = ref(route.params.treatment);
const column = ref([]);
const empty = ref(true);

function getColumn(){
  let parent_name = ""
  if (problem.value) {
    if (treatment.value) {
      parent_name = dataStore.getTreatmentNameById(Number(treatment.value))
    } else {
      parent_name = dataStore.getProblemNameById(Number(problem.value))
    }
  } else {
    if (treatment.value) {
      parent_name = dataStore.getTreatmentNameById(Number(treatment.value))
    } else {
      parent_name = "NaN"
    }
  }
  axios.get(`/api/v1/mini_articles.json`, {
    params: {
      parent_name: parent_name
    }
  })
  .then((res) => {
    if(res.data.notice == "MiniArticle Not Found."){
      empty.value = true
    }else{
      column.value = res.data.data
      empty.value = false
    }
  })
}
</script>

<template>

<div class="d-flex justify-center mb-10" style="min-height:600px;">
  <div class="mt-5 mx-0 w-100" style="max-width:1100px;">
    <div class="mx-2 my-2">
      <div class="font-weight-bold text-h6 mx-1 mr-10 text-black">
        {{ breadClumbs }}
      </div>
    </div>
    <v-row no-gutters class="my-5 px-2 px-sm-4 w-100">
      <v-col cols="4">
        <v-btn variant="text" class="rounded-e-0 rounded-s-lg text-white font-weight-bold w-100" style="background-color: #CCA8D2;">トップ</v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn @click="router.push(`${path}clinics`)"
         variant="outlined" color="primary" class="rounded-0 font-weight-bold w-100">
         クリニック
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn @click="router.push(`${path}records`)"
         variant="outlined" color="primary" class="rounded-s-0 rounded-e-lg font-weight-bold w-100">
         口コミ
        </v-btn>
      </v-col>
    </v-row>
    <v-card id="column-card" class="pa-3 pa-sm-5 mx-1">
      <div v-if="!empty" v-html="column.body"></div>
      <div v-else class="d-flex justify-center">
        <div class="font-weight-bold text-h6 ma-10">データなし</div>
      </div>
    </v-card>
  </div>
</div>

</template>

<style>
#column-card {
  box-shadow: 0px 5px 25px 0px #363B4026;
}
</style>