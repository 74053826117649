<script setup lang="ts">
import { useLoginStore } from '@/store/login';
import { useDataStore } from '@/store/data';
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import RecordBox from '@/components/CommonComponents/RecordBox.vue';
import CommonLoading from '@/components/CommonLoading.vue';
import LoginDialog from '@/components/LoginRecommendDialog.vue';
import CommonSelectDialog from '@/components/ConsoleComponents/CommonSelectDialog.vue';

interface Clinic {
  id: number,
  name: string,
  address: string,
  title: string,
  description: string,
  homepage: string,
  access: string,
  dayoff: string,
  opentime: string,
  clinic_likes_count: number,
  clinic_records_count: number,
  send_clinic_image: string,
  calc_evaluation_average: number,
  related_records: Array<any>,
  treatment_data: Array<string>,
  tag_data: Array<string>,
  is_liked: boolean,
  like_id: number | undefined
}

const props = defineProps({
  clinic_id: {
    type: String,
    required: true,
  },
  city: {
    type: String,
    required: true,
  },
  prefecture: {
    type: String,
    required: true,
  },
  area: {
    type: String,
    required: true,
  },
})

// 初期処理
onMounted(async () => {
  await getClinicData();
  await getRecordsData();
})

// 絞り込み・ページネーション処理
onBeforeRouteUpdate(async (to, _from, next) => {
  records.value = []
  nowLoadRecords.value = true
  param.page = Number(to.query.page) || 1
  param.part_id = to.query.prt || null
  param.problem_id = to.query.prb || null
  param.treatment_id = to.query.tret || null
  await getRecordsData();
  next()
})

const route = useRoute();
const router = useRouter();
const loginStore = useLoginStore();
const dataStore = useDataStore();
const nowLoadClinic = ref(true)
const nowLoadRecords = ref(false)

const toggleDialog = ref(false)
const toggleNarrow = ref(false)
const showDialogs = {
  partSelect: ref(false),
  problemSelect: ref(false),
  treatmentSelect: ref(false),
}

const narrowValue = {
  part: ref(''),
  problem: ref(''),
  treatment: ref(''),
}

const param = {
  part_id: route.query.prt || null,
  problem_id: route.query.prb || null,
  treatment_id: route.query.tret || null,
  page: Number(route.query.page) || 1,
}
const clinic = ref(<Clinic>{})
const records = ref([])
const currentTab = ref(route.query.tab || 'detail')

function likeClinic(){
  if (!loginStore.loggedIn){
    toggleDialog.value = true;
    return
  }
  if(clinic.value.is_liked){
    clinic.value.is_liked = false;
    clinic.value.clinic_likes_count -= 1;
    axios.delete(`/api/v1/clinic_likes/${clinic.value.like_id}.json`)
    .then((_res) => {
    })
    .catch((err) => {
      console.log(err);
    })
  }else{
    clinic.value.is_liked = true;
    clinic.value.clinic_likes_count += 1;
    axios.post(`/api/v1/clinic_likes.json`, {
      clinic_id: props.clinic_id,
      user_id: loginStore.currentID
    })
    .then((res) => {
      clinic.value.like_id = res.data.data.id;
    })
    .catch((err) => {
      console.log(err);
    })
  
  }
}
async function getClinicData(){
  axios
    .get(`/api/v1/clinics/${props.clinic_id}.json`, {
      params: {
        uuid: loginStore.currentID,
        q: {
          treatments_name_eq: param.treatment_id,
          report_problem_id_eq: param.problem_id,
          report_part_id_eq: param.part_id,
        }
      }
    })
    .then(response => (clinic.value = response.data.data))
    .finally(() => nowLoadClinic.value = false)
}

async function getRecordsData(){
  axios
    .get(`/api/v1/records.json`, {
      params: {
        q: {
          clinic_id_eq: props.clinic_id,
          treatments_name_eq: param.treatment_id,
          report_part_id_eq: param.part_id,
          report_problem_id_eq: param.problem_id,
        },
        uuid: loginStore.currentID,
        order: "DESC",
        page: param.page,
        per: 10
      }
    })
    .then(response => (records.value = response.data.data))
    .finally(() => nowLoadRecords.value = false)
}

function executeSearch(){
  router.push({
    query: {
      currentTab: 'records',
      prb: dataStore.problems.find(p => p.name == narrowValue.problem.value)?.id,
      tret: dataStore.treatments.find(t => t.name == narrowValue.treatment.value)?.id,
      prt: dataStore.parts.find(pt => pt.name == narrowValue.part.value)?.id,
    }
  })
}

function clearValue(){
  narrowValue.part.value = '';
  narrowValue.problem.value = '';
  narrowValue.treatment.value = '';
}
</script>

<template>
<div class="d-flex justify-center" style="min-height:600px; height:auto;">
  <CommonLoading class="mt-16" v-if="nowLoadClinic" />
  <div v-else class="mt-15 mx-0 w-100" style="max-width:1000px;">
    <div class="d-flex flex-column mx-3">
      <div class="d-flex">
        <div class="font-weight-bold text-h6 text-sm-h5">{{ clinic.name }}</div>
        <!-- <v-btn @click="likeClinic()" size="35" class="ml-2 d-flex align-center" rounded="circle" style="z-index: 100; box-shadow: 0px 0px 4px 0px #00000040;">
          <v-icon color="red" class="mt-1" icon="mdi-heart-outline"></v-icon>
        </v-btn> -->
      </div>
      <v-row no-gutters class="w-100">
        <!-- <v-col class="d-flex align-center" style="max-width: 220px;">
          <v-rating color="grey" read-only density="compact" active-color="yellow" v-model="clinic.calc_evaluation_average" :star-size=30 />
          <p class="mt-1 ml-2 font-weight-bold text-primary text-body-1">{{ clinic.calc_evaluation_average }}</p>
          <p class="mt-1 ml-1 font-weight-bold text-caption">({{ clinic.clinic_records_count }})</p>
        </v-col> -->
        <v-col class="mt-1 d-flex align-center text-no-wrap">
          <v-icon color="grey" class="ml-2">mdi-map-marker-outline</v-icon>
          <p>{{ clinic.address }}</p>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4 w-100">
        <v-col class="" cols="12" md="6">
          <img src="https://fakeimg.pl/600x400?text=NO+IMAGE&font=bebas" style="object-fit: cover; width: 100%; aspect-ratio: 3/2;">
        </v-col>
        <v-col class="mt-2 mt-md-6 px-3" cols="12" md="6">
          <p class="font-weight-bold text-primary text-h6">{{ clinic.title }}</p>
          <p class="mt-3 font-weight-bold text-grey-darken-2">{{ clinic.description }}</p>
        </v-col>
      </v-row>
    </div>
    <!-- <v-row no-gutters class="my-5 px-2 px-sm-4 w-100">
      <v-col cols="6">
        <v-btn v-if="currentTab == 'detail'" variant="text" class="rounded-e-0 rounded-s-lg text-white font-weight-bold w-100" style="background-color: #CCA8D2;">クリニックの詳細</v-btn>
        <v-btn @click="currentTab = 'detail'" v-else variant="outlined" color="primary" class="rounded-e-0 rounded-s-lg text-white font-weight-bold w-100">クリニックの詳細</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn @click="currentTab = 'records'" v-if="currentTab == 'detail'" variant="outlined" color="primary" class="rounded-s-0 rounded-e-lg font-weight-bold w-100" style="">口コミ</v-btn>
        <v-btn v-else variant="text" class="rounded-s-0 rounded-e-lg font-weight-bold w-100 text-white" style="background-color: #CCA8D2;">口コミ</v-btn>
      </v-col>
    </v-row> -->
    <v-card v-if="currentTab == 'detail'" rounded="0" variant="flat" class="w-100 mb-15">
      <v-row no-gutters>
        <v-col cols="3" class="py-3 d-flex justify-center" id="bottom-border" style="background-color: #DACFBF; border-top: 1px solid #BCBCBC;">
          <p class="text-white font-weight-bold text-caption text-sm-body-1">住所</p>
        </v-col>
        <v-col cols="9" class="py-3 px-2 px-sm-6" id="bottom-border" style="border-top: 1px solid #BCBCBC;">
          <p class="text-black text-caption text-sm-body-1">{{ clinic.address }}</p>
        </v-col>
        <v-col cols="3" class="py-3 d-flex justify-center" id="bottom-border" style="background-color: #DACFBF;">
          <p class="text-white font-weight-bold text-caption text-sm-body-1">アクセス</p>
        </v-col>
        <v-col cols="9" class="py-3 px-2 px-sm-6" id="bottom-border">
          <p class="text-black text-caption text-sm-body-1">{{ clinic.access }}</p>
        </v-col>
        <v-col cols="3" class="py-3 d-flex justify-center" id="bottom-border" style="background-color: #DACFBF;">
          <p class="text-white font-weight-bold text-caption text-sm-body-1">休診日</p>
        </v-col>
        <v-col cols="9" class="py-3 px-2 px-sm-6" id="bottom-border">
          <p class="text-black text-caption text-sm-body-1">{{ clinic.dayoff }}</p>
        </v-col>
        <v-col cols="3" class="py-3 d-flex justify-center" id="bottom-border" style="background-color: #DACFBF;">
          <p class="text-white font-weight-bold text-caption text-sm-body-1">営業時間</p>
        </v-col>
        <v-col cols="9" class="py-3 px-2 px-sm-6" id="bottom-border">
          <p class="text-black text-caption text-sm-body-1">{{ clinic.opentime }}</p>
        </v-col>
        <v-col cols="3" class="py-3 d-flex justify-center" id="bottom-border" style="background-color: #DACFBF;">
          <p class="text-white font-weight-bold text-caption text-sm-body-1">ホームページ</p>
        </v-col>
        <v-col cols="9" class="py-3 px-2 px-sm-6" id="bottom-border">
          <a class="text-black text-caption text-sm-body-1" :href="clinic.homepage">ホームページ</a>
        </v-col>
        <v-col cols="3" class="py-3 d-flex justify-center" id="bottom-border" style="background-color: #DACFBF;">
          <p class="text-white font-weight-bold text-caption text-sm-body-1">施設情報</p>
        </v-col>
        <v-col cols="9" class="py-3 px-2 px-sm-6" id="bottom-border">
          <p class="text-black text-caption text-sm-body-1">{{ clinic.tag_data.join('/') }}</p>
        </v-col>
      </v-row>
    </v-card>
    <div v-else class="d-flex flex-column align-center px-2">
      <v-card variant="outlined" color="#DACAB2" class="w-100 pa-4" style="max-width:970px;">
        <v-btn variant="text" class="justify-start w-100 text-grey-darken-2 font-weight-bold" 
        :append-icon="toggleNarrow ? 'mdi-chevron-down' : 'mdi-chevron-left'" @click="toggleNarrow = !toggleNarrow">
        絞り込み
      </v-btn>
        <v-divider></v-divider>
        <v-row class="mt-3" v-if="toggleNarrow">
          <v-col class="d-flex font-weight-bold align-center justify-center text-no-wrap" cols="2">部位</v-col>
          <v-col cols="10" @click="showDialogs.partSelect.value = true">
            <v-text-field :value="narrowValue.part.value" style="max-width:300px;" variant="solo-filled" flat rounded="lg" density="compact" single-line hide-details disabled>
            </v-text-field>
          </v-col>
          <v-col class="d-flex font-weight-bold align-center justify-center text-no-wrap" cols="2">悩み</v-col>
          <v-col cols="10" @click="showDialogs.problemSelect.value = true">
            <v-text-field :value="narrowValue.problem.value" style="max-width:300px;" variant="solo-filled" flat rounded="lg" density="compact" single-line hide-details disabled>
            </v-text-field>
          </v-col>
          <v-col class="d-flex font-weight-bold align-center justify-center text-no-wrap" cols="2">施術</v-col>
          <v-col cols="10" @click="showDialogs.treatmentSelect.value = true">
            <v-text-field :value="narrowValue.treatment.value" style="max-width:400px;" variant="solo-filled" flat rounded="lg" density="compact" single-line hide-details disabled>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn color="grey" class="font-weight-bold px-6 mx-1" @click="clearValue" variant="tonal">クリア</v-btn>
            <v-btn color="primary" class="font-weight-bold px-6 mx-1" @click="executeSearch" variant="tonal">絞り込む</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-row no-gutters class="pb-10">
        <v-col cols="12" class="my-4 px-4" v-for="record in records" >
          <RecordBox :record="record"/>
        </v-col>
      </v-row>
    </div>
  </div>
</div>
<LoginDialog :toggle-dialog="toggleDialog" @update:toggle-dialog="(toggle:boolean) => toggleDialog = toggle" />
<CommonSelectDialog :multiple="false" :toggle="showDialogs.problemSelect.value" :list-data="dataStore.problemNames()" 
@update:toggle="(val) => showDialogs.problemSelect.value = val"
@send-val="(val) => narrowValue.problem.value = val[0]"/>
<CommonSelectDialog :multiple="false" :toggle="showDialogs.treatmentSelect.value" :list-data="dataStore.treatmentNames()" 
@update:toggle="(val) => showDialogs.treatmentSelect.value = val"
@send-val="(val) => narrowValue.treatment.value = val[0]"/>
<CommonSelectDialog :multiple="false" :toggle="showDialogs.partSelect.value" :list-data="dataStore.partNames()" 
@update:toggle="(val) => showDialogs.partSelect.value = val"
@send-val="(val) => narrowValue.part.value = val[0]"/>
</template>

<style scoped>
#bottom-border {
  border-bottom: 1px solid #BCBCBC;
}
</style>