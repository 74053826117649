<script setup lang="ts">
import { ref } from 'vue';
import Prefecture from '@/components/HomePrefecture.vue';
import Map from '@/components/HomeMap.vue';
import AreaSelectSP from '@/components/ResponsiveAreaSelect.vue';

const regionKey = ref("A4")
</script>

<template>
<v-card rounded="lg" variant="outlined" color="grey-lighten-2" style="box-shadow: 0px 1px 2px 0px #00000040;">
  <v-row id="area-large" no-gutters>
    <v-col cols="7">
      <Map @send-area="(val) => regionKey = val"></Map>
    </v-col>
    <v-col style="background-color: #DACFBF;">
      <Prefecture use-for="search" :region-key="regionKey"></Prefecture>
    </v-col>
  </v-row>
  <div id="area-sp">
    <AreaSelectSP use-for="select"/>
  </div>
</v-card>
</template>

<style scoped>
#area-large{
  display: none;
}
#area-sp{
  display: block;
}
@media (min-width:800px) {
  #area-large{
    display: flex;
  }
  #area-sp{
    display: none;
  }
}
</style>