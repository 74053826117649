<script setup lang="ts">
</script>

<template>
  <div class="w-100">
    <div class="d-flex justify-center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>