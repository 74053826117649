<script setup lang="ts"> 
import { useRouter } from 'vue-router';
import { useLoginStore } from '@/store/login';
import axios from 'axios';

const props = defineProps({
  record: {
    type: Object,
    required: true,
  },
})

const loginStore = useLoginStore();
const emits = defineEmits<{
  (e: 'likeRecord'): void
}>();

const router = useRouter();
function moveRecordDetail(id:string){
  router.push(`/records/${id}`);
}

function likeRecord(){
  if (!loginStore.loggedIn){
    emits('likeRecord');
    return;
  }
  const id = props.record.id;
  if(props.record.is_liked){
    props.record.is_liked = false;
    props.record.record_likes_count -= 1;
    axios.delete(`/api/v1/record_likes/${props.record.like_id}.json`)
    .then((_res) => {
    })
  }else{
    props.record.is_liked = true;
    props.record.record_likes_count += 1;
    axios.post(`/api/v1/record_likes.json`, {
      record_id: id,
      user_id: loginStore.currentID
    })
    .then((res) => {
      props.record.like_id = res.data.id;
    })
  
  }
}

</script>

<template>
<div class="d-flex flex-column">
  <v-card variant="outlined" class="w-100 position-relative rounded-lg d-flex justfiy-center text-center elevation-0" style="border-color: #DACAB2; max-width: 1100px;">
    <div @click="moveRecordDetail(record.id)" class="d-flex flex-column">
      <div class="pa-4 d-flex align-center w-100">
        <v-row no-gutters>
          <v-col cols="12" md="5">
            <img :src="record.before_image" class="rounded-s-lg" style="object-fit: cover; width: 50%; aspect-ratio: 1;">
            <img :src="record.after_image" class="rounded-e-lg" style="object-fit: cover; width: 50%; aspect-ratio: 1;">
          </v-col>
          <v-col>
            <div class="d-flex flex-column w-100 h-75">
              <v-row no-gutters class="w-100 h-100 ml-5 justify-start">
                <v-col cols="8" class="mt-4 d-flex font-weight-bold text-lg-h6 text-md-body-2 pa-md-1">
                    <v-icon start icon="mdi-hospital-building" size="small" class="pt-0"></v-icon><p style="color:#CCA8D2">店舗：</p>
                    {{ record.clinic_name }}
                </v-col>
                <v-col cols="12" class="mt-3 d-flex text-lg-h6 font-weight-bold text-md-body-2 pa-md-1">
                  <v-icon start icon="mdi-tag-outline" size="small" class="pt-0"></v-icon><p class="text-no-wrap" style="color:#CCA8D2;">施術：</p>
                  <div class="justify-start text-left">
                    <v-chip density="compact" v-for="(treatment, index) in record.treatment_data" :key="index"
                            variant="outlined" style="border-color: #CCA8D2;" class="px-4 mx-1">
                      {{ treatment }}
                    </v-chip>
                  </div>
                </v-col>
                <v-col cols="12" class="mt-3 d-flex font-weight-bold text-lg-h6 text-md-body-2 pa-md-1">
                  <v-icon start icon="mdi-currency-jpy" size="small" class="pt-1"></v-icon><p style="color:#CCA8D2">値段：</p>
                  {{ record.price }}円
                </v-col>
                <v-col cols="12" class="mt-3 d-flex text-body-1 text-md-body-2 pa-md-1">
                  <v-rating color="grey" read-only density="compact" active-color="yellow" v-model="record.calc_evaluation_average" :star-size=30 />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="text-left px-6 pb-6 font-weight-bold text-body-1 text-md-body-2">カウンセラーさんから施術内容、料金の説明を受けました。
        今後やってみたい施術はあるか？という事前の問診票について、悩みはあるが何をしていいか分からないと伝えたところ私が
        気になる部分について適切な施術を施術前後の写真を使って丁寧に説明をしてくだいました。とてもわかりやすく参考...
      </div>
    </div>
    <div class="d-flex justify-end position-absolute" style="right:0; top:30px;">
      <div class="d-flex flex-column justify-center align-center">
        <v-btn @click="likeRecord" class="mr-3 mt-n5" size="45" rounded="circle" style="z-index: 100;">
          <div class="d-flex flex-column">
            <v-icon color="red" class="pt-2" :icon="record.is_liked ? 'mdi-heart' : 'mdi-heart-outline'"></v-icon>
            <div class="text-caption text-grey">{{ record.record_likes_count }}</div>
          </div>
        </v-btn>
      </div>
      <div class="d-flex flex-column justify-center align-center">
        <v-btn class="mr-3 mt-n5" size="45" rounded="circle" style="z-index: 100;">
          <div class="d-flex flex-column">
            <v-icon color="#c9baa5" class="pt-2" icon="mdi-comment-processing-outline"></v-icon>
            <div class="text-caption text-grey">{{ record.record_comments_count }}</div>
          </div>
        </v-btn>
      </div>
    </div>
  </v-card>
  <div class="w-100 d-flex justify-end" style="max-width: 1100px;">
    <v-btn @click="moveRecordDetail(record.id)" variant="plain" style="transform: translate(-10%, -120%);">詳しく見る ></v-btn>
  </div>
</div>
</template>