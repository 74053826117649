<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useDataStore } from '@/store/data';

interface AllPrefectures {
  [key: string]: Array<Prefectures>
}

interface Prefectures {
  name: string
  id: string
}
const dataStore = useDataStore();
const props = defineProps({
  useFor: {
    type: String,
    required: true,
  },
})

const emits = defineEmits<{
  (e: 'sendArea', data: string): void
}>();

const router = useRouter()
const areaKey = ref("A1")
const areaData = [
  {"name":"関東","id":"A1"},
  {"name":"関西","id":"A2"},
  {"name":"北海道・東北","id":"A3"},
  {"name":"中国・四国","id":"A4"},
  {"name":"九州・沖縄","id":"A5"},
]
const allPrefecturesSP:AllPrefectures = {
  "A1":[
        {"name":"東京都","id":"1"},
        {"name":"神奈川県","id":"2"},
        {"name":"栃木県","id":"3"},
        {"name":"群馬県","id":"4"},
        {"name":"埼玉県","id":"5"},
        {"name":"千葉県","id":"6"},
        {"name":"山梨県","id":"7"},
        {"name":"茨城県","id":"8"}
    ],
    "A2":[
        {"name":"大阪府","id":"9"},
        {"name":"京都府","id":"10"},
        {"name":"兵庫県","id":"11"},
        {"name":"奈良県","id":"12"},
        {"name":"滋賀県","id":"13"},
        {"name":"和歌山県","id":"14"},
        {"name":"三重県","id":"15"}
    ],
    "A3":[
        {"name":"福島県","id":"16"},
        {"name":"宮城県","id":"17"},
        {"name":"山形県","id":"18"},
        {"name":"秋田県","id":"19"},
        {"name":"岩手県","id":"20"},
        {"name":"青森県","id":"21"},
        {"name":"北海道","id":"22"}
    ],
    "A4":[
        {"name":"鳥取県","id":"22"},
        {"name":"岡山県","id":"23"},
        {"name":"島根県","id":"24"},
        {"name":"広島県","id":"25"},
        {"name":"山口県","id":"26"},
        {"name":"香川県","id":"27"},
        {"name":"徳島県","id":"28"},
        {"name":"愛媛県","id":"29"},
        {"name":"高知県","id":"30"}
    ],
    "A5":[
        {"name":"福岡県","id":"29"},
        {"name":"大分県","id":"30"},
        {"name":"宮崎県","id":"31"},
        {"name":"熊本県","id":"32"},
        {"name":"佐賀県","id":"33"},
        {"name":"長崎県","id":"34"},
        {"name":"鹿児島県","id":"35"},
        {"name":"沖縄県","id":"36"}
  ]
}

const changeAreaKey = (data: string) => {
  areaKey.value = data
}

function searchWithArea(areaName:string){
  if (props.useFor == "select"){
    const prefectureId = dataStore.prefectures.find((prefecture) => prefecture.name == areaName)?.id;
    router.push(`/medical/loc/${prefectureId}`);
  }else if(props.useFor == "dialog"){
    emits('sendArea', areaName)
  }
}

function colorSetter(key: string){
  if(key === areaKey.value){
    return "#F8F7F6"
  }else{
    return "#FFFFFF"
  }
}
</script>

<template>
  <v-row no-gutters>
    <v-col cols="4" class="bg-white">
      <v-btn @click="changeAreaKey(area.id)" variant="text" class="w-100 rounded-0" :style="{ 'background-color':colorSetter(area.id) }" v-for="area in areaData">{{ area.name }}</v-btn>
    </v-col>
    <v-col cols="8">
      <v-row no-gutters class="py-2 mx-2">
        <v-col cols="6" class="pa-1" v-for="item in allPrefecturesSP[areaKey]">
          <v-btn @click="searchWithArea(item.name)" variant="text" class="w-100 bg-white">{{ item.name }}</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>