import { defineStore } from 'pinia'
import { ref, Ref } from 'vue'
import axios from 'axios'

interface treatment {
  id: number,
  name: string,
  part_id?: number,
  category_id?: number
  related_problems?: Array<number>
}

interface prefecuture {
  id: number,
  name: string,
  region: string
}

interface city {
  id: number,
  name: string,
  prefecture_id: number
}

interface area {
  id: number,
  name: string,
  city_id: number,
  prefecture_id: number
}

interface clinicGroup {
  id: number,
  name: string,
}

export const useDataStore = defineStore('data', () => {

  const treatments: Ref<Array<treatment>> = ref([])
  const problems: Ref<Array<treatment>> = ref([])
  const parts: Ref<Array<treatment>> = ref([])
  const prefectures: Ref<Array<prefecuture>> = ref([])
  const cities: Ref<Array<city>> = ref([])
  const clinicGroups: Ref<Array<clinicGroup>> = ref([])
  const areas: Ref<Array<area>> = ref([])


  const fetchTreatments = async () => {
    const response = await axios.get('api/v1/treatments.json')
    treatments.value = response.data.data
  }

  const fetchProblems = async () => {
    const response = await axios.get('api/v1/problems.json')
    problems.value = response.data.data
  }

  const fetchParts = async () => {
    const response = await axios.get('api/v1/parts.json')
    parts.value = response.data.data
  }

  const fetchClinicGroups = async () => {
    const response = await axios.get('api/v1/clinic_groups.json')
    clinicGroups.value = response.data.data
  }

  const fetchArea = async () => {
    const response = await axios.get('api/v1/area.json')
    prefectures.value = response.data.data.prefecture
    cities.value = response.data.data.city
    areas.value = response.data.data.area
  }

  // すべての名前を取得
  function prefectureNames(){
    return prefectures.value.map(p => p.name)
  }

  function treatmentNames(){
    return treatments.value.map(t => t.name)
  }
  function problemNames(){
    return problems.value.map(p => p.name)
  }
  function partNames(){
    return parts.value.map(p => p.name)
  }
  function clinicGroupNames(){
    return clinicGroups.value.map(c => c.name)
  }
  // IDから名前を取得
  function treatmentNameByPartId(partId: number){
    return treatments.value.filter(t => t.part_id == partId).map(t => t.name)
  }
  function proplemsByCategoryId(categoryId: number){
    return problems.value.filter(p => p.category_id == categoryId).map(p => p.name)
  }
  function getTreatmentsByProblemId(problemId: number){
    return treatments.value.filter(trt => trt.related_problems?.includes(problemId))
  }
  function getAreaNameByCityId(cityId: number){
    return areas.value.filter(a => a.city_id == cityId).map(a => a.name)
  }
  function getPrefectureNameById(prefectureId: number){
    return prefectures.value.find(p => p.id == prefectureId)?.name
  }
  function getProblemNameById(problemId: number){
    return problems.value.find(p => p.id == problemId)?.name
  }
  function getTreatmentNameById(treatmentId: number){
    return treatments.value.find(t => t.id == treatmentId)?.name
  }
  function getPartNameById(partId: number){
    return parts.value.find(p => p.id == partId)?.name
  }
  function getCityNameByIdAndName(cityId: number | string){
    if(typeof cityId === 'string'){
      return cities.value.find(c => c.name == cityId)?.name
    }else{
      return cities.value.find(c => c.id == cityId)?.name
    }
  }
  function getAreaNameByIdAndName(areaId: number | string){
    if(typeof areaId === 'string'){
      return areas.value.find(a => a.name == areaId)?.name
    }else{
      return areas.value.find(a => a.id == areaId)?.name
    }
  }
  function getAreaByPrefectureId(prefectureId: number){
    return areas.value.filter(a => a.prefecture_id == prefectureId)
  }
  function getCitiesByPrefectureIdOrName(prefectureId: number | string){
    if(typeof prefectureId === 'string'){
      prefectureId = prefectures.value.find(p => p.name == prefectureId)?.id || 0
      return cities.value.filter(c => c.prefecture_id == prefectureId)
    }else{
      return cities.value.filter(c => c.prefecture_id == prefectureId)
    }
  }
  function getAreaByCityIdOrName(cityId: number | string){
    if(typeof cityId === 'string'){
      cityId = cities.value.find(c => c.name == cityId)?.id || 0
      return areas.value.filter(a => a.city_id == cityId)
    }else{
      return areas.value.filter(a => a.city_id == cityId)
    }
  }
  return { treatments, problems, parts, clinicGroups, prefectures, cities, areas,
    fetchTreatments, fetchProblems, fetchParts, fetchArea,
    fetchClinicGroups, treatmentNames, problemNames, partNames, prefectureNames,
    clinicGroupNames, treatmentNameByPartId, proplemsByCategoryId,
    getCitiesByPrefectureIdOrName, getAreaNameByCityId, getPrefectureNameById, getProblemNameById, getTreatmentNameById,
    getCityNameByIdAndName, getAreaNameByIdAndName, getTreatmentsByProblemId,
    getAreaByPrefectureId, getPartNameById,
    getAreaByCityIdOrName };
  },
  {
    persist: {
      storage: sessionStorage,
    }
  }
);