<script setup lang="ts">
import { PropType } from "vue";
import { useLoginStore } from '@/store/login';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';

interface Clinic {
  id: number,
  name: string,
  access: string,
  dayoff: string,
  opentime: string,
  clinic_likes_count: number,
  clinic_records_count: number,
  send_clinic_image: string,
  calc_evaluation_average: number,
  related_records: Array<any>,
  treatment_data: Array<string>,
  tag_data: Array<string>,
  is_liked: boolean,
  city_id: number,
  prefecture_id: number,
  area_id: number,
  like_id: number | undefined
}

const router = useRouter(); 
const route = useRoute();
const currentPath = route.path.split('/')[1];

const props = defineProps({
  clinic: {
    type: Object as PropType<Clinic>,
    required: true,
  },
})

const emits = defineEmits<{
    (e: 'likeClinic'): void
}>();

const loginStore = useLoginStore();

function likeClinic(){
  if (!loginStore.loggedIn){
    emits('likeClinic');
    return;
  }
  const id = props.clinic.id;
  if(props.clinic.is_liked){
    props.clinic.is_liked = false;
    props.clinic.clinic_likes_count -= 1;
    axios.delete(`/api/v1/clinic_likes/${props.clinic.like_id}.json`)
    .then((_res) => {
    })
    .catch((err) => {
      console.log(err);
    })
  }else{
    props.clinic.is_liked = true;
    props.clinic.clinic_likes_count += 1;
    axios.post(`/api/v1/clinic_likes.json`, {
      clinic_id: id,
      user_id: loginStore.currentID
    })
    .then((res) => {
      props.clinic.like_id = res.data.data.id;
    })
    .catch((err) => {
      console.log(err);
    })
  }
}


</script>

<template>
<div>
<v-card variant="outlined" style="border-color: #DACAB2; max-width: 1100px;">
  <div @click="router.push(`/${currentPath}/loc/${clinic.prefecture_id}/${clinic.city_id}/${clinic.area_id}/${clinic.id}`)" class="pa-4 d-flex flex-column">
    <p class="font-weight-bold text-body-2 text-sm-body-1 mb-2 pr-6">{{ clinic.name }}</p>
    <v-row no-gutters>
      <v-col cols="12" lg="3">
        <div>
          <img src="https://fakeimg.pl/600x400?text=NO+IMAGE&font=bebas" class="rounded-0" style="object-fit: cover; width: 100%; aspect-ratio: 3/2;">
        </div>
      </v-col>
      <v-col cols="12" lg="9">
        <v-row no-gutters class="w-100 h-75 ml-1 ml-sm-2 ml-md-4 justify-start">
          <v-col cols="12" class="d-flex mt-2 mt-sm-2 mt-md-0">
            <v-icon class="text-body-1 text-sm-h6 d-flex align-center" start color="grey">mdi-hospital-building</v-icon>
            <p class="text-primary text-caption text-sm-body-2 font-weight-bold">アクセス：</p>
            <p class="font-weight-bold text-caption text-sm-body-2">{{ clinic.access }}</p>
          </v-col>
          <v-col cols="12" class="d-flex mt-2 mt-sm-5 mt-md-0">
            <v-icon class="text-body-1 text-sm-h6 d-flex align-center" start color="grey">mdi-tag-outline</v-icon>
            <p class="text-primary text-caption text-sm-body-2 font-weight-bold text-no-wrap">営業時間：</p>
            <p class="font-weight-bold text-caption text-sm-body-2">{{ clinic.opentime }}</p>
          </v-col>
          <v-col cols="12" class="d-flex mt-2 mt-sm-5 mt-md-0">
            <v-icon class="text-body-1 text-sm-h6 d-flex align-center" start color="grey">mdi-currency-jpy</v-icon>
            <p class="text-primary text-caption text-sm-body-2 font-weight-bold">休診日　：</p>
            <p class="font-weight-bold text-caption text-sm-body-2">{{ clinic.dayoff }}</p>
          </v-col>
          <v-col cols="12" class="d-flex mt-2  mt-sm-5 mt-md-0">
            <p class="font-weight-bold text-grey text-caption text-lg-body-2">{{ clinic.tag_data.join('/') }}</p>
          </v-col>
          <!-- <v-col cols="12" sm="6" class="d-flex align-center mt-2 mt-sm-5 mt-md-0" style="max-width: 170px;">
            <v-rating color="grey" readonly density="compact" active-color="yellow" v-model="clinic.calc_evaluation_average" :star-size=30 />
            <p class="mt-1 ml-2 font-weight-bold text-caption text-md-body-2">{{ clinic.calc_evaluation_average }}</p>
          </v-col>
          <v-col cols="12" sm="6" class="mt-2 mt-sm-5 mt-md-0 d-flex align-center">
            <v-icon color="#DACAB2" start class="ml-sm-6 text-h6 d-flex align-center">mdi-note-text-outline</v-icon>
            <p class="ml-n1 font-weight-bold text-caption text-no-wrap text-md-body-2">{{ clinic.clinic_records_count }}件</p>
            <v-icon color=red start class="ml-2 text-h6 d-flex align-center">mdi-heart</v-icon>
            <p class="ml-n1 font-weight-bold text-caption text-no-wrap text-md-body-2">{{ clinic.clinic_likes_count }}件</p>
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
  </div>

  <!-- <div class="d-flex justify-end position-absolute" style="right:0; top:30px;">
    <div class="d-flex flex-column justify-center align-center">
      <v-btn @click="likeClinic" class="mr-3 mt-n5" size="45" rounded="circle" style="z-index: 100;">
        <div class="d-flex flex-column">
          <v-icon color="red" class="pt-2" :icon="clinic.is_liked ? 'mdi-heart' : 'mdi-heart-outline'"></v-icon>
          <div class="text-caption text-grey">{{ clinic.clinic_likes_count }}</div>
        </div>
      </v-btn>
    </div>
  </div> -->
</v-card>
</div>
</template>