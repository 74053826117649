<script setup lang="ts">
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';

window.scrollTo(0, 0);

defineProps({
  part: {
    type: String,
    required: true
  }
})
</script>

<template>
<MainHeader />
<router-view></router-view>
<MainFooter main-color="ede9e2"/>
</template>