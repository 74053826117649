<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'

const route = useRoute()
const router = useRouter();
const sortedBy = ref();
const selectItems = [
    { label: '新着順', value: 'date' },
    { label: 'いいね数順', value: 'likes' },
    { label: 'コメント数順', value: 'comments' },
    { label: '評価順', value: 'evaluation' },
    { label: '価格順', value: 'price' }
];
const param = {
  area: route.query.area || null,
  treatment: route.query.treatment || null,
  clinicName: route.query.clinicName || null,
}
onBeforeRouteUpdate((to, _from, next) => {
  param.area = to.query.area || null
  param.treatment = to.query.treatment || null
  param.clinicName = to.query.clinicName || null
  sortedBy.value = selectItems.find((item) => item.value === to.query.sortedBy) || { label: '新着順', value: 'date' }
  next()
})

watch(sortedBy, (newSortedBy) => {
    if(newSortedBy.value) {
      router.push({
        query: {
          area: param.area,
          treatment: param.treatment,
          clinicName: param.clinicName,
          sortedBy: newSortedBy.value,
          recordPage: 1
        }
      })
    }
});
</script>

<template>
<div class="d-flex w-100">
  <div class="w-100">
    <v-select v-model="sortedBy" :items="selectItems" item-title="label" item-value="value" return-object
     class="font-weight-bold" placeholder="並び替え" variant="solo-filled" bg-color="F8F7F6" flat hide-details rounded density="compact">
    </v-select>
  </div>
</div>

</template>

<style scoped>
</style>