<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { checkData } from '@/functions/checkData'

onBeforeMount(() => {
  console.log('root mounted');
  checkData();
});


</script>

<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>