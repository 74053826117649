import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from '@/App.vue';
import router from '@/router';
import { vuetifyPlugin } from '@/plugins/vuetify';

const pinia = createPinia();
const app = createApp(App);

app.use(vuetifyPlugin);
app.use(pinia);
pinia.use(piniaPluginPersistedstate)
app.use(router);

document.addEventListener("DOMContentLoaded", () => {
  app.mount('#app');
});