<script setup lang="ts">

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
})
</script>

<template>
<v-card variant="elevated" class="w-100 rounded-lg text-center elevation-4 mb-16" style="max-width: 1100px;">
  <div v-if="props.show" class="d-flex align-center pa-5 w-100">
    <div class="w-100">
      <div class="d-flex flex-row mb-10" v-for="i in 4" >
        <img src="@/assets/images/The_Pentagon.jpg" class="rounded-circle d-flex" style="object-fit: cover; width:40px; height:40px;">
        <v-row no-gutters>
          <v-col cols="6" class="d-flex justify-start font-weight-bold pl-2">
            ユーザー名
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-icon @click="console.log('test')" icon="mdi-dots-horizontal"></v-icon>
          </v-col>
          <v-col cols="12" class=" text-left pl-2 font-weight-bold text-body-2 mt-2">
            初めまして。
            1点お伺いしたいのですが、3回目とのことで修正日はかかりましたでしょうか？初めまして。
            1点お伺いしたいのですが、3回目とのことで修正日はかかりましたでしょうか？初めまして。
            1点お伺いしたいのですが、3回目とのことで修正日はかかりましたでしょうか？
          </v-col>
          <v-col cols="12" class="d-flex mt-3">
            <div class="text-grey text-caption mr-5">2023.11.27</div>
            <div v-ripple @click="console.log('ts')" class="text-primary text-caption">返信</div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="text-h6 my-10 font-weight-bold">Coming Soon</div>
  </div>
</v-card>
</template>