<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useDataStore } from '@/store/data';

const router = useRouter();
const dataStore = useDataStore();

interface Problem {
  name: string,
  items: string[]
}

const props = defineProps<{
  problems: Problem
}>()

function moveTo(name: string){
  const problemId = dataStore.problems.find((problem) => problem.name === name)?.id
  router.push(`/medical/prb/${problemId}`)
}
</script>

<template>
<v-card rounded="lg" style="box-shadow: 0px 0px 10px 0px #E5E5E5;">
  <div class="w-100" style="background-color: #DACFBF;">
    <div class="px-4 py-2 text-white font-weight-bold ml-2 ml-sm-6 text-h6">{{ problems.name }}</div>
  </div>
  <div class="pa-1 pa-sm-4">
    <v-row no-gutters>
      <v-col class="px-1 px-sm-4 my-1" cols="12" sm="6" v-for="item in problems.items">
        <v-btn @click="moveTo(item)" variant="text" class="text-caption text-sm-body-2 font-weight-bold text-grey-darken-2 d-flex justify-start w-100">
          {{ item }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</v-card>
</template>