<script setup lang="ts">
import { ref } from 'vue'
import { useDataStore } from '@/store/data';
import { useRouter } from 'vue-router';
import CommonSelectDialog from '@/components/ConsoleComponents/CommonSelectDialog.vue';
import { partIcon } from '@/modules/partIcon'

interface Icon {
  icon: number,
  desc: string,
}

const router = useRouter();
const dataStore = useDataStore();
const showDialog = ref(false)
const selectDataInit = ref(['すべて'])
const selectData = ref([''])
const partId = ref(0)
const icons: Icon[] = [
  {icon:0,desc:"肌"},{icon:1,desc:"目"},{icon:2,desc:"まつ毛"},{icon:3,desc:"鼻"},{icon:4,desc:"口元"},
  {icon:16,desc:"背中"},{icon:6,desc:"輪郭"},{icon:17,desc:"お腹"},{icon:15,desc:"胸"},
  {icon:8,desc:"髪の毛"},{icon:9,desc:"首"},{icon:10,desc:"肩"},{icon:11,desc:"ワキ"},{icon:7,desc:"耳"},
  {icon:13,desc:"手"},{icon:12,desc:"腕"},{icon:18,desc:"脚"},{icon:19,desc:"デリケート"},{icon:20,desc:"尻"},
  {icon:14,desc:"ネイル"},{icon:21,desc:"全身"},{icon:22,desc:"その他"},{icon:5,desc:"歯"}
]
function selectFunc(val: string){
  partId.value = dataStore.parts.find((part) => part.name === val)?.id || 1
  selectData.value = selectDataInit.value.concat(dataStore.treatments.filter(x => x.part_id === partId.value).map((treatment) => treatment.name))
  showDialog.value = true
}

function moveTo(name: string){
  if(name === 'すべて'){
    router.push(`/medical/prt/${partId.value}`)
  }else{
    const treatmentId = dataStore.treatments.find((treatment) => treatment.name === name)?.id || 1
    router.push(`/medical/prt/${partId.value}/${treatmentId}`)
  }
}
</script>

<template>
<div class="d-flex justify-center">
  <div class="mt-5" style="width:95%; max-width: 1100px;">
    <v-slide-group show-arrows>
      <v-slide-group-item v-for="item in icons" :key="item.desc">
        <div class="mx-1 mx-sm-2 d-flex align-center flex-column">
          <v-btn @click="selectFunc(item.desc)" size="45" rounded="circle">
            <img height="40" width="40" :src="partIcon[item.icon]">
          </v-btn>
          <p class="font-weight-bold text-grey-darken-2 text-caption">{{ item.desc }}</p>
        </div>
      </v-slide-group-item>
    </v-slide-group>
  </div>
</div>

<CommonSelectDialog v-if="showDialog" :multiple="false" :toggle="showDialog" :list-data="selectData" 
@update:toggle="(val) => showDialog = val"
@send-val="(val) => moveTo(val[0])"/>
</template> 

<style scoped>

</style>