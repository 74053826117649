<script setup lang="ts">
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useLoginStore } from '@/store/login';
const router = useRouter();
const route = useRoute();
const loginStore = useLoginStore();
const currentPath = route.path.split('/')[1];

function moveTo(link:string){
  router.push(link);
}

function logout(){
  loginStore.logout();
  router.push('/');
}

const tabNames = [
  {name: 'medical', text: '美容医療'},
]

const currentTab = ref(currentPath);

const drawer = ref(false);
</script>

<template>
<div id="header-large" class="w-100 bg-primary mt-n16">
  <div class="d-flex justify-center">
    <div class="my-8 mx-10 pa-2 rounded-pill bg-white w-100" style="max-width:1200px;">
      <v-row>
        <v-col class="d-flex align-center">
          <img @click="moveTo('/')" src="@/assets/images/logo.png" width="100px" class="ml-3 mt-1">
          <v-btn @click="moveTo('/' + item.name)" density="compact" v-for="item in tabNames" variant="text" class="font-weight-bold" style="color:#DACAB2">{{ item.text }}</v-btn>
          <!-- <v-card variant="outlined" color="primary" style="border-width: 2px;" class="rounded-pill px-4">
            <div class="d-flex">
              <img class="ml-n1" src="@/assets/images/mypage.png" width="30px">
              <div class="d-flex flex-column">
                <p class="mb-n1 text-primary font-weight-bold text-caption">ログイン</p>
                <p class="mt-n1 text-primary font-weight-bold text-caption">会員登録</p>
              </div>
            </div>
          </v-card>
          <v-btn variant="text" class="text-primary text-h5"><v-icon>mdi-magnify</v-icon></v-btn> -->
        </v-col>
      </v-row>
    </div>
  </div>
</div>

<div id="header-sp">
  <v-app-bar app color="white" flat >
    <img @click="moveTo('/')" src="@/assets/images/logo.png" width="100px" class="ml-3 mt-5">
    <v-spacer></v-spacer>
    <v-btn @click="moveTo('/search')" size="30" icon class="mt-5">
      <v-icon size=30 color="primary">mdi-magnify</v-icon>
    </v-btn>
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="primary" class="mt-5">
      <v-icon size="30">
        mdi-menu
      </v-icon>
    </v-app-bar-nav-icon>
  </v-app-bar>
  <div class="w-100" style="position: fixed; top:64px">
    <v-divider id="divider"></v-divider>
  </div>
</div>

<v-navigation-drawer v-model="drawer" location="right" temporary>
  <div v-if="loginStore.loggedIn">
    <div class="ma-2 text-body-2 font-weight-bold">ようこそ、{{ loginStore.userName }}さん</div>
    <v-btn @click="moveTo('/mypage')" class="ml-4 mb-4 font-weight-bold text-white elevation-0" rounded prepend-icon="mdi-account-outline" style="background-color: #CCA8D2;">
      マイページ
    </v-btn>
  </div>
  <div v-else>
    <div class="ma-2 text-body-2 font-weight-bold">ようこそ、ゲストさん</div>
    <v-btn @click="moveTo('/login')" class="ml-4 mb-4 font-weight-bold text-white elevation-0" rounded prepend-icon="mdi-account-outline" style="background-color: #CCA8D2;">
      ログイン・会員登録
    </v-btn>
  </div>  
  <v-divider></v-divider>
  <div v-for="item in tabNames">
    <v-btn @click="moveTo('/' + item.name)" class="font-weight-bold text-body-2" :class="{'text-primary': currentTab == item.name}" variant="text">・{{ item.text }}</v-btn>
    <v-divider></v-divider>
  </div>
  <v-btn @click="moveTo('/search-clinics')" class="font-weight-bold text-body-2" variant="text">クリニックを探す</v-btn>
  <v-divider></v-divider>
  <v-btn @click="moveTo('/search-records')" class="font-weight-bold text-body-2" variant="text">みんなの記録(口コミ)</v-btn>
  <v-divider></v-divider>
  <v-btn @click="moveTo('/boards')" class="font-weight-bold text-body-2" variant="text">掲示板</v-btn>
  <v-divider></v-divider>
  <v-btn href="http://articles.b-rec.com/" class="font-weight-bold text-body-2" variant="text">美容情報</v-btn>
  <v-divider></v-divider>
  <v-btn variant="outlined" color="red" class="mt-10 ml-2" v-if="loginStore.loggedIn" @click="logout">
    ログアウト
  </v-btn>
</v-navigation-drawer>

</template>

<style scoped>
#header-large{
  display: none;
}
#header-sp{
  display: block;
}
@media (min-width:400px) {
  #header-large{
    display: block;
  }
  #header-sp{
    display: none;
  }
}

#btn {
  border-color: #CCA8D2;
}

#divider {
  border-color: #CCA8D2;
  opacity: 0.7;
}
</style>