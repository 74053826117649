<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios';
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import ProgressReport from '@/components/CommonProgressReportBox.vue'
import RecordComment from '@/components/RecordComment.vue'

interface ProgressReport {
  date: string
  memo: string
  all_images: Array<string>
  pain: number
  scar: number
  swelling: number
  satisfaction: number
  translate_emoji: string
}

interface Record {
  id: number
  user_id: string
  user_name: string
  clinic_name: string
  treatment_data: Array<string>
  price: number
  skill_evaluation: number
  service_evaluation: number
  completion_evaluation: number
  menu_evaluation: number
  price_evaluation: number
  satisfaction_evaluation: number
  other_memo: string
  treatment_date: string
  all_before_images: Array<string>
  all_progress_reports: Array<ProgressReport>
}

const router = useRouter();
const record = ref(<Record>{})
const isShowed = ref(false);
const currentTab = ref('detail')

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
})

const link = '/api/v1/records/' + props.id + '.json'
onMounted(() => {
  window.scrollTo(0, 0);
  axios.get(link)
  .then((response) => {
    record.value = response.data.data
  })
})

function colorSetter(tabName: string){
    if (currentTab.value === tabName){
      return 'primary'
    }else{
      return 'grey'
    }
}

</script>

<template>
<MainHeader />
<div v-if="record.other_memo" class="d-flex justify-center" style="min-height:1000px;">
  <div id="record-detail-large" class="mt-7">
    <div class="d-flex justify-center my-7">
      <v-btn @click="currentTab = 'detail'" variant="outlined" width="50%" :color="colorSetter('detail')" class="rounded-s-lg rounded-e-0 font-weight-bold" style="max-width:400px;">記録(口コミ)詳細</v-btn>
      <v-btn @click="currentTab = 'comment'" variant="outlined" width="50%" :color="colorSetter('comment')" class="rounded-e-lg rounded-s-0 font-weight-bold" style="max-width:400px;">コメント</v-btn>
    </div>
    <div v-if="currentTab == 'detail'" class="d-flex justify-center">
      <v-card variant="outlined" class="w-100 rounded-lg text-center elevation-4 mb-16" style="border-color: #DACAB2; max-width: 1100px;">
        <div class="d-flex align-center pa-5">
          <v-row>
            <v-col @click="router.push({ name: 'user', params: {userId: record.user_id}})" cols="6" class="d-flex align-center">
              <img src="@/assets/images/The_Pentagon.jpg" class="rounded-circle" style="object-fit: cover; width:40px; height:40px;">
              <div class="pl-3 text-body-2 font-weight-bold" style="color:#938D8F">{{ record.user_name }} さん</div>
            </v-col>
            <v-col cols="6" class="d-flex justify-end align-end">
              <v-btn class="mr-3 mt-n5" style="color:#c9baa5;" width="40" height="40" icon="mdi-comment-processing-outline"></v-btn>
              <v-btn class="mr-3 mt-n5" style="color:rgb(218, 0, 0);" width="40" height="40" icon="mdi-heart"></v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="mb-2 py-1 pl-2 font-weight-bold text-left text-white rounded-e-xl" style="width:220px; background-color: #CCA8D2;">Before {{ record.treatment_date.split('T')[0] }}</div>
        <div class="d-flex justify-center flex-column pa-7">
          <div class="pa-1">
            <v-slide-group show-arrows>
              <v-slide-group-item>
                <v-card v-for="(item, i) in record.all_before_images" :key="i"
                height="300" width=300 color="primary" class="mx-1"><img :src="item" class="w-100 h-100" style="object-fit: cover;"></v-card>
              </v-slide-group-item> 
            </v-slide-group>
          </div>
          <v-row class="d-flex justify-center pt-10 ml-10">
            <v-col cols="12" class="d-flex text-lg-body-1 font-weight-bold text-md-body-2 pa-md-1 my-1">
              <v-icon start icon="mdi-tag-outline" size="small" class="pt-1"></v-icon><p style="color:#CCA8D2; min-width:50px;" >施術　　　　　</p>
              <div class="justify-start text-left">
                <v-chip density="compact" v-for="(item, i) in record.treatment_data" :key="i"
                variant="outlined" style="border-color: #CCA8D2;" class="px-4 mx-1">{{ item }}</v-chip>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex text-lg-body-1 text-md-body-2 pa-md-1 font-weight-bold my-1">
              <v-icon start icon="mdi-hospital-building" size="small" class="pt-1"></v-icon><p style="color:#CCA8D2">店舗　　　　　</p>
              {{ record.clinic_name }}
            </v-col>
            <v-col cols="12" class="d-flex text-lg-body-1 text-md-body-2 pa-md-1 font-weight-bold my-1">
              <v-icon start icon="mdi-currency-jpy" size="small" class="pt-1"></v-icon><p style="color:#CCA8D2">値段　　　　　</p>
              {{ record.price }}円
            </v-col>
            <v-col cols="12" class="d-flex text-lg-body-1 align-center text-md-body-2 pa-md-1 font-weight-bold my-1">
              <v-icon start icon="mdi-star-outline" size="small"></v-icon><p style="color:#CCA8D2">施術・技術　　</p>
              <v-rating v-model="record.skill_evaluation" density="compact" readonly color="grey" active-color="yellow-darken-2"></v-rating>
              <p class="ml-2">{{ record.skill_evaluation }}</p>
            </v-col>
            <v-col cols="12" class="d-flex text-lg-body-1 align-center text-md-body-2 pa-md-1 font-weight-bold my-1">
              <v-icon start icon="mdi-star-outline" size="small"></v-icon><p style="color:#CCA8D2">接客・雰囲気　</p>
              <v-rating v-model="record.service_evaluation" density="compact" readonly color="grey" active-color="yellow-darken-2"></v-rating>
              <p class="ml-2">{{ record.service_evaluation }}</p>
            </v-col>
            <v-col cols="12" class="d-flex text-lg-body-1 align-center text-md-body-2 pa-md-1 font-weight-bold my-1">
              <v-icon start icon="mdi-star-outline" size="small"></v-icon><p style="color:#CCA8D2">仕上がり　　　</p>
              <v-rating v-model="record.completion_evaluation" density="compact" readonly color="grey" active-color="yellow-darken-2"></v-rating>
              <p class="ml-2">{{ record.completion_evaluation }}</p>
            </v-col>
            <v-col cols="12" class="d-flex text-lg-body-1 align-center text-md-body-2 pa-md-1 font-weight-bold my-1">
              <v-icon start icon="mdi-star-outline" size="small"></v-icon><p style="color:#CCA8D2">値段評価　　　</p>
              <v-rating v-model="record.price_evaluation" density="compact" readonly color="grey" active-color="yellow-darken-2"></v-rating>
              <p class="ml-2">{{ record.price_evaluation }}</p>
            </v-col>
            <v-col cols="12" class="d-flex text-lg-body-1 align-center text-md-body-2 pa-md-1 font-weight-bold my-1">
              <v-icon start icon="mdi-star-outline" size="small"></v-icon><p style="color:#CCA8D2">メニュー評価　</p>
              <v-rating v-model="record.menu_evaluation" density="compact" readonly color="grey" active-color="yellow-darken-2"></v-rating>
              <p class="ml-2">{{ record.menu_evaluation }}</p>
            </v-col>
            <v-col cols="12" class="d-flex text-lg-body-1 align-center text-md-body-2 pa-md-1 font-weight-bold my-1">
              <v-icon start icon="mdi-star-outline" size="small"></v-icon><p style="color:#CCA8D2">総合評価　　　</p>
              <v-rating v-model="record.satisfaction_evaluation" density="compact" readonly color="grey" active-color="yellow-darken-2"></v-rating>
              <p class="ml-2">{{ record.satisfaction_evaluation }}</p>
            </v-col>
            <v-col cols="12" class="d-flex text-lg-body-1 text-md-body-2 pa-md-1 font-weight-bold my-1">
              <v-icon start icon="mdi-note-outline" size="small" class="pt-1"></v-icon><p style="color:#CCA8D2">メモ</p>
            </v-col>
          </v-row>
          <div class="rounded-lg mx-12 mt-4 pa-4 text-left text-body-2 font-weight-bold" style="background-color: #F6F4F0;">
            {{ record.other_memo }}
          </div>
        </div>
        <!-- レコードの長さが0以上かつisShowedがFalseの場合表示 -->
        <div v-if="!isShowed && record.all_progress_reports.length != 0" class="d-flex flex-column">
          <ProgressReport :progress-report="record.all_progress_reports[0]" :before-day="record.treatment_date"/>
        </div>
        <!-- isShowedがTrueなら表示 -->
        <div v-if="isShowed" class="d-flex flex-column" v-for="(item, i) in record.all_progress_reports" :key="i">
          <ProgressReport :progress-report="item" :before-day="record.treatment_date" />
        </div>
        <!-- レコードの長さが1以上かつisShowedがFalseの場合表示 -->
        <div v-if="!isShowed && record.all_progress_reports.length > 1" class="py-10">
          <v-btn @click="isShowed = !isShowed" width="250" rounded="xl" 
          variant="outlined" class="text-grey font-weight-bold">
          もっと見る
          </v-btn>
        </div>
      </v-card>
    </div>
    <div v-else-if="currentTab == 'comment'" class="d-flex justify-center">
      <RecordComment :show="true"/>
    </div>
  </div>

  <div id="record-detail-sp" class="w-100 mt-0">
    <!-- タブ切り替え -->
    <div class="d-flex">
      <div class="w-50">
        <v-btn @click="currentTab = 'detail'" variant="text" :color="colorSetter('detail')" class="w-100 font-weight-bold align-end">詳細</v-btn>
        <v-divider :color="colorSetter('detail')" style="opacity: 1;"></v-divider>
      </div>
      <div class="w-50">
        <v-btn @click="currentTab = 'comment'" variant="text" :color="colorSetter('comment')" class="w-100 font-weight-bold align-end">コメント</v-btn>
        <v-divider :color="colorSetter('comment')" style="opacity: 1;"></v-divider>
      </div>
    </div>
    <!-- 本体 -->
    <div v-if="currentTab == 'detail'" class="mb-10">
      <v-btn @click="router.push({ name: 'user', params: {userId: record.user_id}})"
       variant="text" class="font-weight-bold">{{ record.user_name}}さん</v-btn>
      <div class="py-1 pl-2 font-weight-bold text-body-2 text-left text-white rounded-e-xl"
      style="width:170px; background-color: #CCA8D2;">
        Before {{ record.treatment_date.split('T')[0] }}
      </div>
      <v-slide-group show-arrows class="mt-3 mx-1">
        <v-slide-group-item>
          <v-card v-for="_i in 4"
          height="95" width=95 color="primary" class="mx-1"><img src="https://fakeimg.pl/600x400?text=NO+IMAGE&font=bebas" class="w-100 h-100" style="object-fit: cover;"></v-card>
        </v-slide-group-item> 
      </v-slide-group>
      <v-slide-group class="mt-3 mx-1">
        <v-slide-group-item>
          <v-chip density="compact" v-for="(item, i) in record.treatment_data" :key="i"
          variant="outlined" style="border-color: #CCA8D2;" class="text-body-2 px-4 mx-1">{{ item }}</v-chip>
        </v-slide-group-item> 
      </v-slide-group>
      <v-row no-gutters class="pa-3 text-body-2">
        <v-col cols="3" class="mt-2">
          <div class="text-primary font-weight-bold">施術日</div>
        </v-col>
        <v-col cols="9" class="mt-2 d-flex justify-end">
          <div class="font-weight-bold">{{ record.treatment_date.split('T')[0] }}</div>
        </v-col>
        <v-col cols="3" class="mt-2">
          <div class="text-primary font-weight-bold">店舗</div>
        </v-col>
        <v-col cols="9" class="mt-2 d-flex justify-end">
          <div class="font-weight-bold">{{ record.clinic_name }}</div>
        </v-col>
        <v-col cols="3" class="mt-2">
          <div class="text-primary font-weight-bold">値段</div>
        </v-col>
        <v-col cols="9" class="mt-2 d-flex justify-end">
          <div class="font-weight-bold">{{ record.price.toLocaleString() }} 円</div>
        </v-col>
        <v-col cols="3" class="mt-2"> 
          <div class="text-primary font-weight-bold">担当者</div>
        </v-col>
        <v-col cols="9" class="mt-2 d-flex justify-end">
          <div class="font-weight-bold">{{ record.clinic_name }}</div>
        </v-col>
        <v-col cols="3" class="mt-2">
          <div class="text-primary font-weight-bold">施術・技術</div>
        </v-col>
        <v-col cols="9" class="d-flex justify-end">
          <v-rating v-model="record.skill_evaluation" style="white-space: revert; overflow: hidden;" readonly density="compact" color="grey" active-color="yellow-darken-2"></v-rating>
        </v-col>
        <v-col cols="3" class="mt-2">
          <div class="text-primary font-weight-bold">価格評価</div>
        </v-col>
        <v-col cols="9" class="mt-1 d-flex justify-end">
          <v-rating v-model="record.price_evaluation" style="white-space: revert; overflow: hidden;" readonly density="compact" color="grey" active-color="yellow-darken-2"></v-rating>
        </v-col>
        <v-col cols="4" class="mt-2">
          <div class="text-primary font-weight-bold">メニュー評価</div>
        </v-col>
        <v-col cols="8" class="mt-1 d-flex justify-end">
          <v-rating v-model="record.menu_evaluation" style="white-space: revert; overflow: hidden;" readonly density="compact" color="grey" active-color="yellow-darken-2"></v-rating>
        </v-col>
        <v-col cols="4" class="mt-2">
          <div class="text-primary font-weight-bold">接客・雰囲気</div>
        </v-col>
        <v-col cols="8" class="mt-1 d-flex justify-end">
          <v-rating v-model="record.service_evaluation" style="white-space: revert; overflow: hidden;" readonly density="compact" color="grey" active-color="yellow-darken-2"></v-rating>
        </v-col>
        <v-col cols="3" class="mt-2"> 
          <div class="text-primary font-weight-bold">仕上がり</div>
        </v-col>
        <v-col cols="9" class="mt-1 d-flex justify-end">
          <v-rating v-model="record.completion_evaluation" style="white-space: revert; overflow: hidden;" readonly density="compact" color="grey" active-color="yellow-darken-2"></v-rating>
        </v-col>
        <v-col cols="3" class="mt-2">
          <div class="text-primary font-weight-bold">総合評価</div>
        </v-col>
        <v-col cols="9" class="mt-1 d-flex justify-end">
          <v-rating v-model="record.satisfaction_evaluation" style="white-space: revert; overflow: hidden;" readonly density="compact" color="grey" active-color="yellow-darken-2"></v-rating>
        </v-col>
        <v-col cols="12" class="mt-2">
          <p class="text-primary font-weight-bold">メモ</p>
        </v-col>
      </v-row>
      <div class="mx-3">
        <v-card class="rounded-lg pa-3 elevation-0 text-body-2" style="background-color: #F6F4F0;">
          {{ record.other_memo }}
        </v-card>
      </div>

      <!-- レコードの長さが0以上かつisShowedがFalseの場合表示 -->
      <div v-if="!isShowed && record.all_progress_reports.length != 0" class="d-flex flex-column">
        <ProgressReport :progress-report="record.all_progress_reports[0]" :before-day="record.treatment_date"/>
      </div>
      <!-- isShowedがTrueなら表示 -->
      <div v-if="isShowed" class="d-flex flex-column" v-for="(item, i) in record.all_progress_reports" :key="i">
        <ProgressReport :progress-report="item" :before-day="record.treatment_date" />
      </div>
      <!-- レコードの長さが1以上かつisShowedがFalseの場合表示 -->
      <div v-if="!isShowed && record.all_progress_reports.length > 1" class="py-10 d-flex justify-center">
        <v-btn @click="isShowed = !isShowed" width="250" rounded="xl" 
          variant="outlined" class="text-grey font-weight-bold">
          もっと見る
        </v-btn>
      </div>
    </div>
    <div v-else>
      <RecordComment :show="true"/>
    </div>
  </div>
</div>
<div v-else style="height:500px;">
  ロード中
</div>
<MainFooter main-color="ede9e2"/>
</template>

<style scoped>
#record-detail-large{
  display: none;
}
#record-detail-sp{
  display: block;
}
@media (min-width:600px) {
  #record-detail-large{
    display: block;
  }
  #record-detail-sp{
    display: none;
  }
}
</style>