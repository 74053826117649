<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useLoginStore } from '@/store/login';
import axios from 'axios';

const props = defineProps({
  record: {
    type: Object,
    required: true,
  },
})

const loginStore = useLoginStore();
const emits = defineEmits<{
  (e: 'likeRecord'): void
}>();

const router = useRouter();
function moveRecordDetail(id:string){
  router.push(`/${props.record.user_id}/${id}`);
}

function likeRecord(){
  if (!loginStore.loggedIn){
    emits('likeRecord');
    return;
  }
  const id = props.record.id;
  if(props.record.is_liked){
    props.record.is_liked = false;
    props.record.record_likes_count -= 1;
    axios.delete(`/api/v1/record_likes/${props.record.like_id}.json`)
    .then((_res) => {
    })
  }else{
    props.record.is_liked = true;
    props.record.record_likes_count += 1;
    axios.post(`/api/v1/record_likes.json`, {
      record_id: id,
      user_id: loginStore.currentID
    })
    .then((res) => {
      props.record.like_id = res.data.id;
    })
  
  }
}

function shorted(text: string, length: number){
  if(text.length > length){
    return text.slice(0, length) + '...';
  }
  return text;
}
</script>

<template>
<v-card class="pa-2 pa-sm-4 position-relative" style="box-shadow: 0px 5px 25px 0px #363B4026;">
  <div @click="moveRecordDetail(record.id)" class="d-flex flex-column">
    <v-row no-gutters>
      <v-col cols="12" md="5">
        <div>
          <img src="https://fakeimg.pl/600x400?text=NO+IMAGE&font=bebas" class="rounded-s-lg" style="object-fit: cover; width: 50%; aspect-ratio: 1;">
          <img src="@/assets/images/The_Pentagon.jpg" class="rounded-e-lg" style="object-fit: cover; width: 50%; aspect-ratio: 1;">
        </div>
      </v-col>
      <v-col cols="12" md="7">
        <v-row no-gutters class="w-100 h-75 ml-1 ml-sm-2 ml-md-4 justify-start">
          <v-col cols="12" class="d-flex mt-sm-5 mt-md-0">
            <v-icon class="text-body-1 text-sm-h6 d-flex align-center" start color="grey">mdi-hospital-building</v-icon>
            <p class="text-primary text-caption text-sm-body-1 font-weight-bold">店舗：</p>
            <p class="font-weight-bold text-caption text-sm-body-1">{{ record.clinic_name }}</p>
          </v-col>
          <v-col cols="12" class="d-flex mt-2 mt-sm-5 mt-md-0">
            <v-icon class="text-body-1 text-sm-h6 d-flex align-center" start color="grey">mdi-tag-outline</v-icon>
            <p class="text-primary text-caption text-sm-body-1 font-weight-bold text-no-wrap">施術：</p>
            <div class="justify-start text-left">
              <v-chip density="compact" v-for="(treatment, index) in record.treatment_data" :key="index"
                      variant="outlined" style="border-color: #CCA8D2;" class="px-4 mx-1">
                {{ shorted(treatment,10) }}
              </v-chip>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex mt-2  mt-sm-5 mt-md-0">
            <v-icon class="text-body-1 text-sm-h6 d-flex align-center" start color="grey">mdi-currency-jpy</v-icon>
            <p class="text-primary text-caption text-sm-body-1 font-weight-bold">値段：</p>
            <p class="font-weight-bold text-caption text-sm-body-1">{{ record.price.toLocaleString() }}円</p>
          </v-col>
          <v-col cols="12" class="d-flex mt-2 mt-sm-5 mt-md-0">
            <v-rating color="grey" readonly density="compact" active-color="yellow" v-model="record.calc_evaluation_average" :star-size=30 />
            <p class="mt-1 ml-2 font-weight-bold">{{ record.calc_evaluation_average }}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
  <div class="d-flex justify-end position-absolute" style="right:0; top:30px;">
    <div class="d-flex flex-column justify-center align-center">
      <v-btn @click="likeRecord()" class="mr-3 mt-n5" size="45" rounded="circle" style="z-index: 100;">
        <div class="d-flex flex-column">
          <v-icon color="red" class="pt-2" :icon="record.is_liked ? 'mdi-heart' : 'mdi-heart-outline'"></v-icon>
          <div class="text-caption text-grey">{{ record.record_likes_count }}</div>
        </div>
      </v-btn>
    </div>
    <div class="d-flex flex-column justify-center align-center">
      <v-btn class="mr-3 mt-n5" size="45" rounded="circle" style="z-index: 100;">
        <div class="d-flex flex-column">
          <v-icon color="#c9baa5" class="pt-2" icon="mdi-comment-processing-outline"></v-icon>
          <div class="text-caption text-grey">{{ record.record_comments_count }}</div>
        </div>
      </v-btn>
    </div>
  </div>
</v-card>
</template>