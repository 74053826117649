<script setup lang="ts">
import SelectDialog from './MainNarrowSelectDialog.vue'
import { useRouter, useRoute } from 'vue-router';
import { reactive, ref, Ref, computed } from 'vue'

// 絞り込み条件の型
interface NarrowSelect {
    price_lteq: number,
    price_gteq: number,
    evaluation_average_gteq: number
}

defineProps({
  showTreatmentSelect: {
    type: Boolean,
    default: true
  }
})

const route = useRoute()
const router = useRouter();
const val = ref(false)
const treatment: Ref<string | null> = ref(null)
const toggleTreatment = ref(false)
const narrowSelects = reactive<NarrowSelect>({
  price_lteq: Number(route.query.maxPrice) || 0,
  price_gteq: Number(route.query.minPrice) || 0,
  evaluation_average_gteq: Number(route.query.evaluation) || 0,
})
const starSelect = [
  {label:"4.5以上", value:4.5},{label:"4以上", value:4},{label:"3.5以上", value:3.5}, {label:"3以上", value:3}, {label:"指定なし", value:0}
]

function onClick() {
  val.value = !val.value
}

function clearParam(){
  narrowSelects.price_lteq = 0
  narrowSelects.price_gteq = 0
  narrowSelects.evaluation_average_gteq = 0
  treatment.value = null
}

function executeSearch(){
  router.push({
    query: {
      treatment: treatment.value,
      minPrice: narrowSelects.price_gteq != 0 ? narrowSelects.price_gteq : null,
      maxPrice: narrowSelects.price_lteq != 0 ? narrowSelects.price_gteq : null,
      evaluation: narrowSelects.evaluation_average_gteq,
      sortedBy: route.query.sortedBy || 'date'
    }
  })
}

const selectedStar = computed({
  get() {
    return starSelect.find((item) => item.value === narrowSelects.evaluation_average_gteq)
  },
  set(item) {
    if (!item) {
      narrowSelects.evaluation_average_gteq = 0
      return
    }
    narrowSelects.evaluation_average_gteq = item.value
  }
})

</script>

<template>
<v-card variant="outlined" class="w-100" style="border-color: #EAE2EB; border-width: 2px;">
  <div @click="onClick" class="d-flex flex-row w-100 font-weight-bold text-grey-darken-1 my-2">
    <v-icon class="ml-2" start color="primary" icon="mdi-tune"></v-icon>
      <div class="text-body-2 d-flex align-center" style="min-width:100px;">絞り込み</div>
      <div class="w-100 d-flex justify-end"><v-icon class="ml-2" start color="grey" :icon="val ? 'mdi-minus' :'mdi-plus'"></v-icon></div>
    </div>
  <div v-if="val" style="height:350px;">
  <div class="w-75 ml-8"> 
    <div class="font-weight-bold mb-2 mt-4">値段</div>
       <div class="d-flex flex-row font-weight-bold">
          <v-text-field label="下限価格" variant="outlined" type="number" reverse density="compact" base-color="primary" color="primary"
            v-model.number="narrowSelects.price_gteq">
          </v-text-field>
          <div class="d-flex align-center mx-2">円</div>
          <div class="mt-2 ml-2 mr-7">〜</div>
          <v-text-field label="上限価格" variant="outlined" type="number" reverse density="compact" base-color="primary" color="primary"
            v-model.number="narrowSelects.price_lteq">
          </v-text-field>
          <div class="d-flex align-center mx-2">円</div>
        </div>
        <div v-if="showTreatmentSelect" class="text-body-1 font-weight-bold mb-2">施術</div>
			  <div v-if="showTreatmentSelect" class="mb-6" style="max-width: 400px;">
          <v-text-field density="compact" hide-details bg-color="#ffffff" single-line readonly
            variant="outlined" base-color="primary" color="primary"
                      @click="toggleTreatment = true" :value="treatment">
          </v-text-field>
			  </div>
        <div class="font-weight-bold mb-2">評価</div>
          <v-select hide-details variant="outlined" density="compact" return-object
          base-color="#CCA8D2" color="#CCA8D2" style="max-width: 230px;"
          v-model="selectedStar" :items="starSelect" item-title="label" item-value="value"
          ></v-select>
    </div>
    <div class="d-flex justify-center flex-row mt-4">
      <v-btn @click="clearParam" class="font-weight-bold mx-2" size="large" variant="tonal" style="background-color: #aca7ac; color:white;">クリア</v-btn>
      <v-btn @click="executeSearch" class="font-weight-bold mx-2" size="large" variant="tonal" style="background-color: #d5b4db; color:white;">検索</v-btn>
    </div>
  </div>
</v-card>
<SelectDialog 
  :toggle-area=false
  :toggle-treatment="toggleTreatment" 
  @send-treatment="(val:string) => treatment = val"
  @update:toggle-treatment="(toggle:boolean) => toggleTreatment = toggle"
/>
</template>
