import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLoginStore = defineStore('login', () => {
  const loggedIn = ref(false)
  const currentID = ref('')
  const userName = ref('')

  function login(id:string, name:string) {
    loggedIn.value = true
    userName.value = name
    currentID.value = id
    console.log('logged in', currentID.value)
  }

  function logout() {
    loggedIn.value = false
    currentID.value = ''
    userName.value = ''
  }
  return { loggedIn, currentID, userName, login, logout};
  },
  {
    persist: {
      storage: sessionStorage,
    }
  }
);

export const useConsoleLoginStore = defineStore('consoleLogin', () => {
  const loggedIn = ref(false)

  function login() {
    loggedIn.value = true
  }

  function logout() {
    loggedIn.value = false
  }

  return { loggedIn, login, logout};
  },
  {
    persist: {
      storage: sessionStorage,
    }
  }
);